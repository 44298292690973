var Router = {
	routes: [],
	visited: [],
	lastVisitedPage: null,
	root: "/",
	rootPage: "accueil",

	// Create new route
	on: function (_path, _handler) {
		if (typeof _path == 'function') {
			_handler = _path;
			_path = this.rootPage;
		}

		this.routes.push({ path: _path, handler: _handler });
		return this;
	},

	// Handle 
	handle: function(_path, isload, lastVisitedPage) {
		var path = _path || this.getCurrentPath();
		for(var i = 0, route; i < this.routes.length, route = this.routes[i]; i++) {
			var base = route.path.toString().replace(/[\\\/]/g, '').replace(/:.*/, '');
			var params_values = path.split("/").slice(1);
			var params_keys = route.path.toString().match(/:\w+/g);
			var params = null;

			if (path.replace(/\/.*/, '') === base) {
				if (params_keys) {
					if (params_values.length) {	
						params = {};
						for(var i = 0; i < params_values.length; i++) {
							if (i < params_keys.length) {
								params[params_keys[i].slice(1)] = params_values[i];
							}
						}
					}
			}

				route.handler.call({}, params, isload, lastVisitedPage);
				return this;
			}
		}
		return this;
	},

	navigate: function(_path) {
		window.location.href = window.location.href.replace(/#(.*)$/, '') + (_path ? "#/" + _path : "");
	},

	// Listen to hash change
	listen: function() {
		var self = this;
		var currentPath = self.getCurrentPath();
		clearInterval(this.interval);
		this.interval = setInterval(function() {
			if(currentPath !== self.getCurrentPath()) {
				var lastVisitedPage = currentPath.replace(/^\//, '').replace(/\/.*/, '');
				self.visited.push(lastVisitedPage || this.rootPage);
				currentPath = self.getCurrentPath();
				self.handle(currentPath, false, lastVisitedPage);
			}
		}, 1000);
		return this;
	},

	// Get active page
	getCurrentPath: function() {
		var match = window.location.href.match(/#(.*)$/);
        return this.clearPath(match ? match[1] : this.rootPage);
	},

	// Clear slashes from the beginning and the end of the path
	clearPath: function(_path) {
		return _path.replace(/^\//, '').replace(/\/$/, '');
	},

	checkAuth: function(callback) {
		$.ajax({
			url: API_URL + 'check-if-connected.php',
			method: 'post'
		})
		.done(function(resp) {
			if (resp && resp.status) {
				if(resp.prenom != "" && resp.name != ""){
					$("#header #profil a").text("Mon profil - ("+resp.prenom.substring(1,0).toUpperCase()+". "+resp.name+")");
				}

				$(".check-cennect").fadeIn();
				callback();
			}
			else {
				Router.navigate("se-connecter-dabbord");
			}
		});
	},

	handleNavigationChange: function(html, isload) {
		/*var currentPage = this.getCurrentPath();
		if (this.visited.indexOf(currentPage) != -1) {
			var lastVisitedPage = this.visited[this.visited.length -1];
			this.visited.splice(this.visited.indexOf(currentPage), 1);
			$("#" + lastVisitedPage).removeClass("pushed");
			$("#" + currentPage).removeClass("popped");

			setTimeout(function() {
				$("#" + lastVisitedPage).remove();
			}, 1000);
		}
		else {
			if (!$("#app-root .content").filter(function(i, item) { return item.className == $(html).get(0).className }).length) 
				$("#app-root").append($(html).addClass(isload ? "pushed" : "transition"));
		}*/

		$("#app-root").html(html);
		$( "html,body" ).scrollTop( 0 );
	}
}

Router
.on(/trajets\/:lat1\/:lng1\/:lat2\/:lng2/, ResultsPageHandler)
//.on(/trajets/, ResultsPageHandler)
.on(/parking/, ParkingPageHandler)
.on(/carte/, MapPageHandler)
.on(/detail-trajet\/:id/, DetailsPageHandler)
.on(/nouveau-trajet/, AddCoursePageHandler)
.on(/se-connecter-dabbord/, ProposeSignPageHandler)
.on(/connexion/, LoginPageHandler)
.on(/inscription/, SignupPageHandler)
.on(/profil/, ProfilePageHandler)
.on(/contact/, ContactPageHandler)
.on(/mes-trajets/, MyTripsPageHandler)
.on(/supprimer-profil/, RemoveProfilePageHandler)
.on(/conditions/, ConditionsPageHandler)
.on(HomePageHandler)
.on(/deconnexion/, loginOutPageHandler)
.on(/oublie-mot-passe/, forgotPassPageHandler)
.on(/demande-cov\/:trajet_id\/:user_id/, DemandeCovPageHandler)
/*
.on(/deconnexion/, function() {
	Router.navigate("connexion");
})
*/ 
.handle(null, true)
.listen()