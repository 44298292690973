$(document).on("click", ".signup .submit", function (e) {
	e.preventDefault();
	$(".signup .submit").addClass("loading");

	$.ajax({
		url: API_URL + 'signup.php',
		type: 'post',
		data: {
			username : $(".signup #username").val(),
			userimg : user_uploaded_image,
			genre: Number($("#genre-homme").is(":checked")),
			email: $(".signup #email").val(),
			email2: $(".signup #confirm-email").val(),
			password1: $(".signup #password").val(),
			password2: $(".signup #confirm-password").val(),
			nom: $(".signup #firstname").val(),
			prenom: $(".signup #lastname").val(),
			tel: $(".signup #tel").val().replace(/\s/g,''),
			age: $(".signup #age").val(),
			id_company: $(".signup #id_company").val(),
			sms: Number($(".signup #sms").is(":checked")),
			newsletter: Number($(".signup #alert_email").is(":checked")),
			conditions: Number($(".signup #check-conditions").is(":checked")),
			alert_email : Number(($(".signup #alert_email").is(":checked")) ? 1 : 0)
		}
	})
	.done(function(resp) {
		$(".signup .error").remove();
		$(".signup .form-error").text("");

		if (resp) {
			$(".signup .submit").removeClass("loading");
			
			if (resp.status) {
				user_uploaded_image = "";
				Router.navigate("profil");
				$(".notif").attr("id", "inscription-new-user");
				notification.show("Votre profil a bien été créé");
			}
			else {
				if (resp.errors && Object.keys(resp.errors).length) {
					for(var key in resp.errors) {
						var $target = $(".signup [name='" + key + "']").parents(".input-container");
						if ($target.length) {
							var margin = parseInt($target.css("margin-bottom")) - 5;
							$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -margin).insertAfter($target);
						}
						
						if( key == "alert_email" ){
							var $target = $(".signup [name='" + key + "']").parents(".checkboxes");
							$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -15).insertAfter($target);
						}
					}

					if (resp.errors.from) {
						$(".signup .form-error").html(resp.errors.from);
					}

					var $firsterror = $(".signup .error").first();
					if($firsterror.length) {
						$("html, body").animate({
							scrollTop: $firsterror.offset().top - 150
						}, 1000);
					}
				}
			}
		}
	})
});

$(document).on("input", ".signup .input", function() {
	$(this).parent().next(".error").remove();
	$(".signup .form-error").text("");
});


$(document).on("click", ".signup #check-conditions ~.txt span", function(){
	Modal.present("conditions");
});


var user_uploaded_image = "";

$(document).on("change", ".signup #image", function(){

	if($(".signup .image-user").hasClass("loading")){
		return false;
	}

	if (this.files && this.files[0]) {

		$(".signup .image-user").addClass("loading");

		/*var reader = new FileReader();

		reader.onload = function(e) {
			
			$(".signup .image-user").css({
				backgroundImage: "url('" + e.target.result + "')",
				backgroundSize: "cover"
			});

			user_uploaded_image = e.target.result;

			user_uploaded_image = user_uploaded_image.split(',')[1];

			$(".signup .image-user").removeClass("loading");

		}
		
		reader.readAsDataURL(this.files[0]);*/

		var file = this.files[0];

		loadImage.parseMetaData(file, function(data) {
			var orientation = 0;
			if (data.exif) {
			    orientation = data.exif.get('Orientation');
			}
			var loadingImage = loadImage(
			   	file,
			    function(canvas) {
			        var base64data = canvas.toDataURL('image/jpeg');

			        $(".signup .image-user").css({
						backgroundImage: "url('" + base64data + "')",
						backgroundSize: "cover"
					});

			        var img_src = base64data.replace(/^data\:image\/\w+\;base64\,/, '');
			        user_uploaded_image = base64data;

			        $(".signup .image-user").removeClass("loading");
			    }, {
			        canvas: true,
			        orientation: orientation
			    }
			);
		});
	}
	else {
		$(".signup .image-user").removeClass("loading");
	}

	
});
  

