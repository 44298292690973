$(document).on("click",".my-trips .item",function(e){
	if(!$(this).hasClass("aucun")){
		Router.navigate("detail-trajet/" + $(this).attr("data-id"));
	}
	
})

$(document).click(function(e){
	if($(e.target).attr("id")=="confirm-message"){
		$("#confirm-message").fadeOut();
	}
});

$(document).on("click",".my-trips .item .delete",function(e){
	var id = $(this).parents(".item").attr("data-id");
	var index = $(this).parents(".item").index();
	if (id) {
		$("#confirm-message").attr("data-id",id);
		$("#confirm-message").attr("data-index",index);
		$("#confirm-message").fadeIn();
	}
	
	return false;
});

$(document).on("click", ".non", function () {
	$("#confirm-message").fadeOut();
});

$(document).on("click", ".oui", function () {
	var id = $(this).parents("#confirm-message").attr("data-id");
	var index  = $(this).parents("#confirm-message").attr("data-index");
	if(id){
		$.ajax({
			url: API_URL + "delete-course.php",
			type: "post",
			data: {
				courseId: id
			}
		})
		.done(function (resp) {
			if (resp && resp.status) {
				$("#mes-trajets .items .item").eq(index).slideUp(300, function () {
					$("#mes-trajets .items .item").eq(index).remove();
					$("#confirm-message").fadeOut();
					$("#confirm-message").removeAttr("data-id");
					$("#confirm-message").removeAttr("data-index");
					if($("#mes-trajets .items .item:not(.aucun)").length == 0 ){
						$(".aucun").fadeIn();
					}
				});
			}
		})
	}
});

$(document).on("click", ".item.aucun .btn", function(){
	Router.navigate("nouveau-trajet");
	return false;
});


