$(document).on("click", ".home .submit", function (e) {
	e.preventDefault();
	$(".error").remove();

	var params = "";

	if ($("#start").attr("data-lat") || $("#stop").attr("data-lat")) {
		params += "/" + $("#start").attr("data-lat");
		params += "/" + $("#start").attr("data-lng");
	}

	if ($("#stop").attr("data-lat")) {
		params += "/" + $("#stop").attr("data-lat");
		params += "/" + $("#stop").attr("data-lng");
	}

	if($("#start").val()!="" && !$("#start").attr("data-lat")) {
		$("<p>",{ class : "error"}).text("Veuillez sélectionner un lieu dans la liste").insertAfter($($("#start").parent()));
		return false;
	}

	if($("#stop").val()!="" && !$("#stop").attr("data-lat")) {
		$("<p>",{ class : "error"}).text("Veuillez sélectionner un lieu dans la liste").insertAfter($($("#stop").parent()));

		return false;
	}
	Router.navigate("trajets" + params);
})

$(document).on("click", ".home #new-course", function (e) {
	e.preventDefault();
	$("#footer .tab-new-course").trigger("click");
});

