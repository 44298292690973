function ProfilePageHandler(params, isload) {
	$.get("pages/profile/profile.html", function(html) {
		Router.handleNavigationChange(html, isload);
		Router.checkAuth(function() {
			Router.handleNavigationChange(html, isload);
			$("body").removeClass("hidden");
			$( "html,body" ).scrollTop( 0 );
			$("#footer .tab-new-course").removeClass("active");

			
			$.ajax({
				url: API_URL + 'my-profil.php',
				type: 'post'
			})
			.done(function(resp) {

				if (resp && resp.status) {

					if(resp.profil.userimg != "" ){
						d = new Date();
						$(".image-user").css({
							backgroundImage: "url('"+img_URL+resp.profil.userimg+"?"+d.getTime()+"')",
							backgroundSize: "cover"
						});
					}

					if (resp.profil.genre_h == "1"){
						$(".profile #genre-homme").get(0).checked = true;
					} else {
						$(".profile #genre-femme").get(0).checked = true;
					}

					//$(".profile #username").val(resp.profil.username);
					$(".profile #firstname").val(resp.profil.name);
					$(".profile #lastname").val(resp.profil.prenom);
					$(".profile #username").val(resp.profil.username);
					$(".profile #email").val(resp.profil.email);
					$(".profile #tel").val(resp.profil.mobile);
					
					$(".profile #age").val(resp.profil.age);
					
					$(".profile #marque").val(resp.profil.vehicule);
					$(".profile #model").val(resp.profil.modele);
					$(".profile #color").val(resp.profil.couleur);
					$(".profile #nbr_places").val(resp.profil.nbr_places);
					$(".profile #plaque").val(resp.profil.num_plaque);

					if(resp.profil.autre_plaques != "" && resp.profil.autre_plaques){
						var autre_plaques= resp.profil.autre_plaques.split(';');
						var input = $(".autre_plaques .input-container").clone();
						autre_plaques.forEach(function(element) {
						  if(element != ""){
								$(input).clone().insertBefore($(".autre_plaques .link"));
								$(".autre_plaques .input-container").last().find("input").val(element);
								$(".autre_plaques .input-container").last().addClass("has-focus");
							}
						})
					}

					$(".profile #co2").val(resp.profil.emission_co2);

					$(".profile #accept_smoke").get(0).checked = parseInt(resp.profil.accepte_fumee);
					$(".profile #accept_animals").get(0).checked = parseInt(resp.profil.accepte_animaux);
					$(".profile #share_costs").get(0).checked = parseInt(resp.profil.partage_frais);
					$(".profile #travel_with").val(resp.profil.voyage_avec);

					if ($(".profile #sms").length > 0) 
						$(".profile #sms").get(0).checked = parseInt(resp.profil.sms);
					$(".profile #alert_email").get(0).checked = parseInt(resp.profil.alert_email);
				}

				$(".profile .input").focusin().focusout();
				$(".profile .dropdown").change();
			})
			
		})
	})
}