$(document).on("click", ".contact .submit", function (e) {
	e.preventDefault();

	$.ajax({
		url: API_URL + 'contact.php',
		type: 'post',
		data: {
			name: $(".contact #name").val(),
			email: $(".contact #email").val(),
			message: $(".contact #message").val()
		}
	})
	.done(function(resp) {
		$(".contact .error").remove();
		$(".contact .form-error").text("");
		
		if (resp) {
			if (resp.status) {
				$(".contact form > .msg").removeClass("form-error").addClass("success").text("Merci votre message a bien été envoyé");
			}
			else {

				if (resp.errors && Object.keys(resp.errors).length) {
					for(var key in resp.errors) {
						var $target = $(".contact [name='" + key + "']").parents(".input-container");
						if ($target.length) {
							var margin = parseInt($target.css("margin-bottom")) - 5;
							$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -margin).insertAfter($target);
						}
					}

					if (resp.errors.from) {
						$(".contact form > .msg").removeClass("success").addClass("form-error").append(resp.errors.from);
					}
				}
			}
		}
	})
})

$(document).on("input", ".contact .input", function() {
	$(this).parent().next(".error").remove();
	$(".contact .msg").text("");
});