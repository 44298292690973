$(document).on("click", "#nouveau-trajet .submit", function(e) {

	if($(this).hasClass("loading")){
		return false;
	}
	$("#nouveau-trajet .submit").addClass("loading");
    e.preventDefault();

	$("#startlat").val($("#start").data("lat"));
	$("#startlng").val($("#start").data("lng"));
	
	$("#stoplat").val($("#stop").data("lat"));
	$("#stoplng").val($("#stop").data("lng"));
	
	$("#steplat").val($("#step").data("lat"));
	$("#steplng").val($("#step").data("lng"));
	
	$(".add-course .error").html("");

    var data = $(this).parent().serializeArray(),
        $type_course = $("#type_course .switcher-item.active").attr("data-value"),
		$course_return = $("#course_return .switcher-item.active").attr("data-value");

		$.each(data,function(index,el){
			if(el.name == "accept_smoke"){
				el.value = "1"
			}
			if(el.name == "accept_animals"){
				el.value = "1"
			}
			if(el.name == "share_costs"){
				el.value = "1"
			}
		});

    data[data.length]= { name:"type_course", value: $type_course };
    data[data.length]= { name:"course_return", value: $course_return };
	
	if($type_course == "1" ){
		data[data.length] = { name:"occ_g_h", value: $(".dropdown[name=mo_g_h]").val() };
		data[data.length] = { name:"occ_g_m", value: $(".dropdown[name=mo_g_m]").val() };
		data[data.length] = { name:"occ_r_h", value: $(".dropdown[name=mo_r_h]").val() };
		data[data.length] = { name:"occ_r_m", value: $(".dropdown[name=mo_r_m]").val() };
		data[data.length] = { name:"occ_status", value: $(".dropdown[name=mo_status]").val() };
		data[data.length] = { name:"occ_date", value: $(".dropdown[name=occ_day]").val()+'.'+$(".dropdown[name=occ_month]").val()+'.'+$(".dropdown[name=occ_year]").val() };

	}  else {
	
		if(!jours.affiner){
			jours.mo =  { 

				"_g_h" : $("select[name=mo_g_h]").val(),
	
				"_g_m" : $("select[name=mo_g_m]").val(),
	
				"_r_h" : $("select[name=mo_r_h]").val(),
	
				"_r_m" : $("select[name=mo_r_m]").val(),
	
				"_status" : $("select[name=mo_status]").val(),
	
				"check" : 1 
			}
	
			jours.th = jours.mo;
	
			jours.tr = jours.mo;
	
			jours.we = jours.mo;
	
			jours.fr = jours.mo;
		}

		$.each(data,function(indexn,el){
			if(el.name == "mo_g_h" ){
				el.value = jours.mo._g_h
			}

			if(el.name == "mo_g_m" ){
				el.value = jours.mo._g_m
			}

			if(el.name == "mo_r_h" ){
				el.value = jours.mo._r_h
			}

			if(el.name == "mo_r_m" ){
				el.value = jours.mo._r_m
			}
			if(el.name == "mo_status" ){
				el.value = jours.mo._status
			}
		});
		
		data[data.length] = { name : "mo", value: jours.mo.check };

		data[data.length] = { name:"tu_g_h", value: jours.tr._g_h };
		data[data.length] = { name:"tu_g_m", value: jours.tr._g_m };
		data[data.length] = { name:"tu_r_h", value: jours.tr._r_h };
		data[data.length] = { name:"tu_r_m", value: jours.tr._r_m };
		data[data.length] = { name:"tu_status", value: jours.tr._status };
		data[data.length] = { name : "tu", value: jours.tr.check };

		data[data.length]= { name:"we_g_h", value: jours.we._g_h };
		data[data.length]= { name:"we_g_m", value: jours.we._g_m };
		data[data.length]= { name:"we_r_h", value: jours.we._r_h };
		data[data.length]= { name:"we_r_m", value: jours.we._r_m };
		data[data.length]= { name:"we_status", value: jours.we._status };
		data[data.length] = { name : "we", value: jours.we.check };

		data[data.length]= { name:"th_g_h", value: jours.th._g_h };
		data[data.length]= { name:"th_g_m", value: jours.th._g_m };
		data[data.length]= { name:"th_r_h", value: jours.th._r_h };
		data[data.length]= { name:"th_r_m", value: jours.th._r_m };
		data[data.length]= { name:"th_status", value: jours.th._status };
		data[data.length] = { name : "th", value: jours.th.check };

		data[data.length]= { name:"fr_g_h", value: jours.fr._g_h };
		data[data.length]= { name:"fr_g_m", value: jours.fr._g_m };
		data[data.length]= { name:"fr_r_h", value: jours.fr._r_h };
		data[data.length]= { name:"fr_r_m", value: jours.fr._r_m };
		data[data.length]= { name:"fr_status", value: jours.fr._status };
		data[data.length] = { name : "fr", value: jours.fr.check };

		data[data.length]= { name:"sa_g_h", value: jours.sa._g_h };
		data[data.length]= { name:"sa_g_m", value: jours.sa._g_m };
		data[data.length]= { name:"sa_r_h", value: jours.sa._r_h };
		data[data.length]= { name:"sa_r_m", value: jours.sa._r_m };
		data[data.length]= { name:"sa_status", value: jours.sa._status };
		data[data.length] = { name : "sa", value: jours.sa.check };

		data[data.length]= { name:"su_g_h", value: jours.su._g_h };
		data[data.length]= { name:"su_g_m", value: jours.su._g_m };
		data[data.length]= { name:"su_r_h", value: jours.su._r_h };
		data[data.length]= { name:"su_r_m", value: jours.su._r_m };
		data[data.length]= { name:"su_status", value: jours.su._status };
		data[data.length] = { name : "su", value: jours.su.check };
	}

	data.accept_animals = ""
	data.share_costs = ""
	data.accept_smoke = ""
	
    $.ajax({
		url: API_URL + 'newCourse.php',
		type: 'post',
		data: data
	})
	.done(function(resp) {
		$(".add-course .error").remove();
		$(".add-course .form-error").text("");
		$("#nouveau-trajet .submit").removeClass("loading");
		if (resp) {
			if (resp.status) {
				if(window.localStorage){
					window.localStorage.setItem("notif","new");
				}

				jours = {
					mo : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" },
					tr : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" },
					we : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" },
					th : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" },
					fr : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" },
					sa : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" }, 
					su : { check : 0, _g_h : "0", _g_m : "0", _r_h : "0", _r_m : "0", _status : "" },
					affiner : false
				};

				Router.navigate("mes-trajets");
			}
			else {

				if (resp.errors && Object.keys(resp.errors).length) {
					for(var key in resp.errors) {
						var $target = $(".add-course [name='" + key + "']").parents(".input-container");
						if ($target.length) {
							var margin = parseInt($target.css("margin-bottom")) - 5;
							$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -margin).insertAfter($target);
						}

						if(key == "type_course" || key == "occ_status"){
							var $target = $(".add-course [name='mo_status']").parents(".input-container");
							if ($target.length) {
								var margin = parseInt($target.css("margin-bottom")) - 5;
								$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -margin).insertAfter($target);
							}
						}
						if(key == "occ_date"){
							var $target = $(".add-course .occ_date table");
							if ($target.length) {
								var margin = parseInt($target.css("margin-bottom")) - 5;
								$($target).append($("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -margin));
							}
						}
					}

					if (resp.errors.from) {
						$(".add-course .form-error").append(resp.errors.from);
					}

					var $firsterror = $(".add-course .error").first();
					if($firsterror.length) {
						$("html, body").animate({
							scrollTop: $firsterror.offset().top - 150
						}, 1000);
					}
				}
			}
		}
	})
});

var jours = {
	mo: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	tr: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	we: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	th: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	fr: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	sa: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	su: { check: 0, _g_h: "0", _g_m: "0", _r_h: "0", _r_m: "0", _status: "" },
	affiner: false
};

var days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

$(document).on("click", "#affiner", function(e){
	e.preventDefault();
	jours.affiner =true;
	$("[name=mo_status]").parent().siblings(".error").remove();
	$("[name=mo_status]").parent().siblings(".success").remove();

	if($("select[name=mo_status]").val()!= "" ){

		$(".section-title.sous-title").addClass("hidden");
		$(".jours").addClass("active");
		$("#valider").addClass("active");
		$(this).addClass("hidden");

		$("html,body").animate({
			scrollTop : $(".jours").offset().top-50-$("#header").height()
		},1000);

		jours.mo =  { 

			"_g_h" : $("select[name=mo_g_h]").val(),

			"_g_m" : $("select[name=mo_g_m]").val(),

			"_r_h" : $("select[name=mo_r_h]").val(),

			"_r_m" : $("select[name=mo_r_m]").val(),

			"_status" : $("select[name=mo_status]").val(),

			"check" : 1 
		}

		$(".jour").find("input").attr("checked",true);

		jours.th = jours.mo;

		jours.tr = jours.mo;

		jours.we = jours.mo;

		jours.fr = jours.mo;

		$(".jour").each(function(index,element){
			if(index >= $(".jour").length-2){
				$(element).find("input").attr("checked",false);
			}else {
				$(element).find("input").attr("checked",true);
			}
		});
		$("#valider").text("Valider " + days[0]);

	} else {
		$("<p>",{class : "error" }).text("Ce champ est obligatoire").css("margin-top", "-30px").insertAfter($("[name=mo_status]").parent());
		jours.affiner =false;
	}
});


$(document).on("click", "#valider", function(e){
	e.preventDefault();
	var jour = $(".jour.active").find("input").attr("id");
	
	jours[jour] = { 

		"_g_h" : $("select[name=mo_g_h]").val(),

		"_g_m" : $("select[name=mo_g_m]").val(),

		"_r_h" : $("select[name=mo_r_h]").val(),

		"_r_m" : $("select[name=mo_r_m]").val(),

		"_status" : $("select[name=mo_status]").val(),
	}

	$("[name=mo_status]").parent().siblings(".error").remove();
	$("[name=mo_status]").parent().siblings(".success").remove();
	$("[name=mo_status]").siblings(".error").remove();
	$("[name=mo_status]").siblings(".success").remove();
	
	/*if(jours[jour]._status == "") {
		
		$(".jour.active").find("input").attr("checked",false);
		jours[jour].check = 0;
		jours[jour] = { 

			"_g_h" : "0",
	
			"_g_m" : "0",
	
			"_r_h" : "0",
	
			"_r_m" : "0",
	
			"_status" : "",
		}
		$("<p>",{class : "success" }).text("Vos changements sont effectués").css("margin-top", "-10px").insertAfter($("[name=mo_status]").parent());
	} else {*/
	$(".jour.active").find("input").attr("checked",true);
	jours[jour].check = 1;
	$("<p>",{class : "success" }).text("Vos changements sont effectués").css("margin-top", "-10px").insertAfter($("[name=mo_status]").parent());
	//}

});

$(document).on("click", ".jour .border", function(e){
	e.preventDefault();

	$("[name=mo_status]").siblings(".error").remove();
	$("[name=mo_status]").siblings(".success").remove();

	if($(this).parent().hasClass("active")){
		var jour = $(".jour.active").find("input").attr("id");

		if($(".jour.active").find("input").is(":checked")){
			
			jours[jour] = { 

				"_g_h" : "0",
		
				"_g_m" : "0",
		
				"_r_h" : "0",
		
				"_r_m" : "0",
		
				"_status" : "",
				"check " : 0
			}
			$(".jour.active").find("input").attr("checked",false);
		} else {
			jours[jour] = { 
	
				"_g_h" : $("select[name=mo_g_h]").val(),
	
				"_g_m" : $("select[name=mo_g_m]").val(),
	
				"_r_h" : $("select[name=mo_r_h]").val(),

				"_r_m" : $("select[name=mo_r_m]").val(),

				"_status" : $("select[name=mo_status]").val(),
			}
			/*if(jours[jour]._status.length == 0) {
				$("[name=mo_status]").parent().append($("<p>",{class:"error"}).text("Ce champ est obligatoire").css("margin-top", "5px"));
			} else {
			*/	
				jours[jour].check = 1;
				$(".jour.active").find("input").attr("checked",true);
				$("[name=mo_status]").parent().append($("<p>",{class:"success"}).text("Vos changements sont effectués").css("margin-top", "25px"));
			//}
		}
	}
});

$(document).on('click', '#have-car', () => {
	console.log($("#have-car").is(":checked"))
	if ($("#have-car").is(":checked")) {
		$("#have-car").parents(".section").css({
			maxHeight: 500+'px'
		});
	} else {
		$("#have-car").parents(".section").css({
			maxHeight: 100 + 'px',
			position: 'relative',
			overflow: 'hidden'
		});
	}
})


$(document).on("click",".jour", function(){
	
	$("[name=mo_status]").parent().siblings(".error").remove();
	$("[name=mo_status]").parent().siblings(".success").remove();
	$(".jour").removeClass("active");
	

	$(this).addClass("active");

	$("#valider").text("Valider " + days[$(this).index()]);

	$("select[name=mo_g_h]").val(0),
	
	$("select[name=mo_g_m]").val(0),
	
	$("select[name=mo_r_h]").val(0),
	
	$("select[name=mo_r_m]").val(0),
	
	$("select[name=mo_status]").val(null);

	$("select[name=mo_status]").parent().removeClass("has-focus");

	$(this).find("input").attr("id");

	if(jours[$(this).find("input").attr("id")]){

		$("select[name=mo_g_h]").val(jours[$(this).find("input").attr("id")]._g_h);
		
		$("select[name=mo_g_m]").val(jours[$(this).find("input").attr("id")]._g_m);
		
		$("select[name=mo_r_h]").val(jours[$(this).find("input").attr("id")]._r_h);
		
		$("select[name=mo_r_m]").val(jours[$(this).find("input").attr("id")]._r_m);

		if(jours[$(this).find("input").attr("id")]._status != "" ){

			$("select[name=mo_status]").parent().addClass("has-focus");
			$("select[name=mo_status]").val(jours[$(this).find("input").attr("id")]._status);
		}
	}
});

$(document).on("click", "#nouveau-trajet #type_course .switcher-item", function(){

	$("[name=mo_status]").parent().siblings(".error").remove();
	$("[name=mo_status]").parent().siblings(".success").remove();

	if($(this).attr("data-value")=="1"){
		$(".section-title.sous-title").addClass("hidden");
		$(".jours").removeClass("active");
		$("#valider").removeClass("active");
		$("#affiner").addClass("hidden");
		$(".occ_date").fadeIn();

		for(var i=0; i<10; i++){
			var select = $("select[name=occ_year] option").eq(0).clone();
			$(select).text((new Date()).getUTCFullYear()+i);
			$(select).val((new Date()).getUTCFullYear()+i);
			$("select[name=occ_year]").append(select);
		}

	} else {
		$(".section-title.sous-title").removeClass("hidden");
		$("#affiner").removeClass("hidden");
		$(".occ_date").fadeOut();
	}

	$("select[name=mo_g_h]").val("0");
	$("select[name=mo_g_m]").val("0");
	$("select[name=mo_r_h]").val("0");
	$("select[name=mo_r_m]").val("0");
	$("select[name=mo_status]").val("");
	$("select[name=mo_status]").val("").parent().removeClass("has-focus");
});

$(document).on("input", ".add-course .input", function(){
	$(this).parent().next(".error").remove();
	$(".add-course .form-error").text("");
});

$(document).on("click", "#nouveau-trajet #course_return .switcher-item", function(){
	//$("#tarjet-simple").fadeOut();

	if($(this).attr("data-value") == 0 ){
		$("#tarjet-simple label").eq(0).text("Heure arrivée");
	} else {
		$("#tarjet-simple label").eq(0).text("Heure retour");
	}

	/*
	if($(this).attr("data-value") == 0 ){
		$("#tarjet-simple").fadeOut();
		var jour = ["mo", "tr", "we", "th", "fr", "sa", "su" ];

		$.each(jour,function(index, element){
			jours[element]._r_h = "0";
			jours[element]._r_m = "0";
		});
		$("[name=mo_r_h").val("0");
		$("[name=mo_r_m").val("0");

	} else {
		$("#tarjet-simple").fadeIn();
	}

	*/
});

$(document).on("click", ".autre_plaques .link", function(){
	var autre_plaques = $(".autre_plaques .input-container").first().clone();
	$(autre_plaques).clone().insertBefore($(this));
});

