function RemoveProfilePageHandler(params, isload) {
	$.get("pages/remove-profile/remove-profile.html", function(html) {
		Router.checkAuth(function() {
			Router.handleNavigationChange(html, isload);
			$("body").removeClass("hidden");			
			$("#footer .tab-new-course").removeClass("active");
			$.ajax({
				url: API_URL + 'my-profil.php',
				type: 'post'
			})
			.done(function(resp) {
				if(resp){
					if(resp && resp.status ){
						$("#remove-profile").attr("data-id",resp.profil.id);
					}

					else {
						Router.navigate("se-connecter-dabbord");
					}
				}
			});
		});
	})
}