$(document).on("click", ".propose-sign .btn", function (e) {
	e.preventDefault();

	if ($(this).is(".login")) {
		Router.navigate("connexion");
	}
	else {
		Router.navigate("inscription");
	}
	
	$(".propose-sign").addClass("transition");
	$(".propose-sign").get(0).scrollHeight;
	$(".propose-sign").addClass("popped");
})