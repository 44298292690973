function ProposeSignPageHandler(params, isload, lastVisitedPage) {
	if (isload) {
		$("#footer .new-course").addClass("active").siblings().removeClass("active");
	}

	Router.lastVisitedPage = lastVisitedPage;

	$.get("pages/propose-sign/propose-sign.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");
		Loader.hide();
	})
}