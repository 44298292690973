$(document).on("click", ".login .submit", function (e) {
	e.preventDefault();

	$.ajax({
		url: API_URL + 'login.php',
		type: 'post',
		data: {
			username: $(".login #username").val(),
			password: $(".login #password").val()
		}
	})
	.done(function(resp) {
		if (resp) {
			if (resp.status) {
				if(resp.prenom && resp.name){
					$("#profil a").text("Mon profil - ("+resp.prenom.substring(1,0).toUpperCase()+". "+resp.name+")");
				}
				
				$(".check-cennect").fadeIn();

				if ($(".modal").attr("data-source") == "login") {
					Modal.dismiss(resp);
				}
				else {
					Router.navigate(Router.lastVisitedPage || "profil");
				}
			}
			else {
				$(".login .form-error").text(resp.msg);
			}
		}
	})
})

$(document).on("input", ".login .input", function() {
	$(".login .form-error").text("");
});

$(document).on("click", ".forgot-pass", function(){
	$("body").removeClass("hidden");
});