$(document).on("click", "#step1 .submit", function(e){
    e.preventDefault();

    if($('#recuperer-pseudo[name=recuperer]:checked').val()){
        $.ajax({
            url: API_URL + 'reset.php',
            type : 'post',
            dataType: "json",
            data : {
                email : $("#email1").val(),
                step : "1",
                type_reset : "pseudo"
            }
        })
        .done(function(resp) {
    
            $("p.error").remove();
    
            if (resp) {
                if (resp.status) {
                    notification.show("Votre pseudo vous a été envoyé par e-mail");
                    
                } else {
                    $("<p>", { class: "error" }).text(resp.error.email).insertAfter("#step1 .input-container");
                }
            }
        })
    } else {
        $.ajax({
            url: API_URL + 'reset.php',
            type : 'post',
            dataType: "json",
            data : {
                email : $("#email1").val(),
                step : "1"
            }
        })
        .done(function(resp) {
    
            $("p.error").remove();
    
            if (resp) {
    
                if (resp.status) {
                    $(".steps").addClass("move");
    
                    notification.show(resp.msg);
    
                    $("#email2").val($("#email1").val()).parent().addClass("has-focus");
                    
                } else {
                    $("<p>", { class: "error" }).text(resp.error.email).insertAfter("#step1 .input-container");
                }
            }
        })
    }
    
    
});

$(document).on("click", "#step2 .submit", function(e){
    e.preventDefault();

    $("#step2 .submit").addClass("loading");

    $.ajax({
		url: API_URL + 'reset.php',
        type : 'post',
        dataType: "json",
		data : {
            email : $("#email2").val(),
            password1 : $("#password1").val(),
            password2 : $("#password2").val(),
            code : $("#code").val(),
            step : "2"
		}
	})
	.done(function(resp) {
        $("#step2 .submit").removeClass("loading");
        $("p.error").remove();

		if (resp) {
			if (resp.status) {
                if(window.localStorage){
                    localStorage.setItem("password-accepte","accept");
                }
                Router.navigate("connexion");
                
			} else {

                if(resp.error.email){

                    $("#email2").parent().after( $("<p>", { class: "error" }).text(resp.error.email));
                } 

                if(resp.error.code){
                    $("#code").parent().after( $("<p>", { class: "error" }).text(resp.error.code));
                }

                if(resp.error.password){
                    $("#password1").parent().after( $("<p>", { class: "error" }).text(resp.error.password));
                }
                
			}
		}
	})
});

