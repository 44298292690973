var API_URL = "https://www.e-covoiturage.ch/littoralparc/app/";
API_URL = "./";
var img_URL = "https://littoralparc.e-covoiturage.ch/userprofiles/";

var Loader = {
	show: function() {
		$("body").addClass("loading");
	},
	hide: function() {
		$("body").removeClass("loading");
	}
};

var Modal = {
	present: function(page, callback) {
		$.get("pages/" + page + "/" + page + ".html", function (html) {
			var $modal = $("<div>", { class: "modal", "data-source": page });
			if ($(html).filter(".content.light").length)
				$modal.addClass("light");
			$modal.html($(html).removeClass("gradient"));
			$modal.prepend('<a href="javascript:void(0)" class="modal-close-btn"></a>');
			$("#app-root").append($modal.show().css("height", window.innerHeight));
			$modal.get(0).scrollHeight;
			$("body").addClass("hidden");
			$modal.addClass("shown");

			if (callback)
				callback();
		})
	},
	dismiss: function(data) {
		$("body").removeClass("hidden");

		var $modal = $(".modal");
		$modal.removeClass("shown");
		setTimeout(function () {
			$modal.remove();
		}, 400);

		$(document).trigger("modal-dismissed", data ? { source: $modal.attr("data-source"), data: data } : null);
	}
};

var notification = {
	show : function(message,callback){

		$(".notif p").text(message);
		$(".notif").addClass("show");
		timenotif = setTimeout (function(){
			$(".notif").removeClass("show");
		},10000);

		if(callback){
			callback();
		}
	}
};

var timenotif ;

(function ($) {
	$('img.inline-svg').each(function(i, img) {
		var $img = $(img);
		var imgID = $img.attr('id');
		var imgClass = $img.attr('class');
		var imgURL = $img.attr('src');
		
		$.get(imgURL, function(data) {
			// Get the SVG tag, ignore the rest
			var $svg = $(data).find('svg');

			// Add replaced image's ID to the new SVG
			if(typeof imgID !== 'undefined') {
				$svg = $svg.attr('id', imgID);
			}
			// Add replaced image's classes to the new SVG
			if(typeof imgClass !== 'undefined') {
				$svg = $svg.attr('class', imgClass+' replaced-svg');
			}

			// Remove any invalid XML tags as per http://validator.w3.org
			$svg = $svg.removeAttr('xmlns:a');

			// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
			if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
				$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
			}

			// Replace image with new SVG
			$img.replaceWith($svg);
		}, 'xml');
		
	});

	if(window.localStorage){
		if (!localStorage.getItem("cookie_allow")) {
			$("#cookie_allow").addClass("is-visible");
			$("#wrapper").addClass("has-cookies-alert");
		}
	}

	$.datepicker.setDefaults({
		firstDay: 1,
		dateFormat: "dd.mm.yy",
		minDate: new Date(),
		prevText: "",
		nextText: "",
		dayNamesMin: [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
		monthNames: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ]
	});

	$(window).on("resize", function() {
		$("#app-root").css("height", window.innerHeight);
	})
	$(window).trigger("resize");
	
}(jQuery));

$(document).on('click', '#cookie_allow .cookie_accept', function() {
	if(window.localStorage){
		localStorage.setItem("cookie_allow", "1"); 
		$("#cookie_allow").fadeIn(400, function() {
			$("#cookie_allow").remove();
			$("#wrapper").removeClass("has-cookies-alert");
		})
	}
});

$(document).on("focusin", ".input", function() {
	$(this).parent().addClass("has-focus");
})

$(document).on("focusout", ".input", function() {
	var $this = $(this);
	setTimeout(function() {
		if ($this.val().trim() == "")
			$this.parent().removeClass("has-focus");
	}, 100);
})

$(document).on("change", ".dropdown", function() {
	var $this = $(this);
	if ($this.val() == "") {
		$(this).parent().removeClass("has-focus");
	}
	else {
		$(this).parent().addClass("has-focus");
	}
})


// Switcher
$(document).on("click", ".switcher:not(.disabled) .switcher-item", function() {
	$(this).addClass("active").siblings().removeClass("active");
});


$(document).on("click", "#header .burger-menu", function() {
	$(this).toggleClass("active");
})

$(document).on("click", "#header .menu .item", function() {
	if ($(this).attr("data-route"))
		$("#header .burger-menu").removeClass("active");
})

$(document).on("click", "#footer .tab", function (e) {
	e.preventDefault();
	var $this = $(this);

	if ($this.is(".tab-search")) {
		Router.navigate("accueil");
	}

	if ($this.is(".tab-new-course")) {
		Router.navigate("nouveau-trajet");
	}

	if ($this.is(".tab-map")) {
		Router.navigate("trajets");
	}

	if ($this.is(".tab-parking")) {
		Router.navigate("parking");
	}

	$this.addClass("active").siblings().removeClass("active");
})

$(document).on("click", "[data-route]", function() {
	Router.navigate($(this).attr("data-route"));
})


$(document).on("click", ".modal .modal-close-btn", function () {
	var $modal = $(this).parent();
	$modal.removeClass("shown");
	setTimeout(function () {
		$modal.remove();
		$("body").removeClass("hidden");
	}, 400);
})

function googleAutocomplete(){
    $('.google-autocomplete').autocomplete({
        source: function(request, response) {
        	autocompleteService = new google.maps.places.AutocompleteService();
        	autocompleteService.getPlacePredictions({
                input: request.term,
                language: "fr"
        	},
        	function(results, status) {
        		var items = results.filter(function(item) { return item.description.indexOf("États-Unis") == -1 });
        		response($.map(items, function(item) {
                    return {
                        label: item.description,
                        value: item.description,
                        id: item.place_id
                    }
                }));
        	})
        },
        select: function(event, ui) {
        	var placedetailService = new google.maps.places.PlacesService(document.createElement("div"));

        	placedetailService.getDetails({
        		placeId: ui.item.id
        	}, function(place) {
        		var coords = place.geometry.location;
        		$(event.target).attr("data-lat", coords.lat).attr("data-lng", coords.lng).trigger("change");
        	})
        }
    });    
}

function getPredefinedLocation(callback) {
	$.ajax({
		url: API_URL + "predefined-locations.php"
	})
	.done(function(location) {
		callback(location);
	})
}

function getCompanies(callback) {
	$.ajax({
		url: API_URL + "predefined-companies.php"
	})
		.done(function (location) {
			callback(location);
		})
}

function generateMaps(center) {
	if ($("#maps").length) {
		var map = new google.maps.Map(document.getElementById('maps'), {
	        center: center || {lat: 46.529187, lng: 6.358827},
	        zoom: 9,
	        fullscreenControl: false,
	        mapTypeControl: false,
	        panControl: false,
	        rotateControl: false,
	        scaleControl: true,
	        //scrollwheel: false,
	        signInControl: false,
	        streetViewControl: false,
	        zoomControl: true,
	        backgroundColor: 'none',
	        gestureHandling: 'greedy'
	    });

	    //map.mapTypes.set('customstyle', new google.maps.StyledMapType([{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#4984a9"},{"lightness":"-8"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#264452"},{"weight":2},{"gamma":0.84},{"lightness":"5"}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"weight":0.6},{"color":"#1a3541"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#023349"},{"lightness":"0"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0d4c69"},{"lightness":"-19"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#0d4c69"},{"lightness":"-17"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#044b6c"},{"lightness":"-15"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#044b6c"},{"lightness":"-2"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#044b6c"},{"lightness":"-14"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#033045"},{"lightness":"-15"}]}], {}));
	    //map.setMapTypeId('customstyle');

	    return map;
	}
}

function checkConfirmation(msg) {
	$("#app-root #confirm-message").remove();
	var $message = $("<div>", { id: "confirm-message" });
	$message.append($("<p>").text(msg));
	$message.append('<button class="btn oui">Oui</button>');
	$message.append('<button class="btn non">Non</button>');
	$("#app-root").append($message);
	$message.fadeIn(400);
}

(function($){
	$.ajax({
		url: API_URL + 'check-if-connected.php',
		method: 'post'
	})
	.done(function(resp) {
		if (resp && resp.status) {
			$(".check-cennect").fadeIn();
		}
	});
}(jQuery));

// cookie  
(function($){
	if(window.localStorage){

		if(localStorage.getItem("cookie_allow")) {
			$("#cookie_allow").hide().remove();
		}
	}
}(jQuery));

$(document).on("click", "#cookie_allow .savoir_plus", function(){
	Modal.present("savoir-plus", function() {})
});

$(document).on("click", "#notif span", function(){

	if(window.localStorage){
		window.localStorage.removeItem("notif");
	}

	$("#notif").removeClass("show");
	clearTimeout(timenotif);
});

