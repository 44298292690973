$(document).on("click", "#back",function(){
    if(window.history){
        window.history.go(-1);
    }
});

$(document).on("click", "#noconnecter .btn",function(){
    Modal.present("login");

    $(document).on("modal-dismissed", function(e, eventData) {
        
    	if (eventData && eventData.source == "login" && eventData.data) {
            if ($(".content.details").length) {
        		$(".details #connecter").fadeIn();
                $(".details #noconnecter").fadeOut();
                $(".details #connexion").fadeOut();

                $("html, body").animate({
                	scrollTop: $(".details #connecter").offset().top - 200
                }, 1000);
            }
            
            if( eventData.data.user_connected_sms == "0"){
                $("#connecter .desc").last().text("Pour pouvoir contacter le covoitureur, veuillez d'abord accepter de ").append("<a href='#/profil'>recevoir les sms</a>");
            } else if($("#connecter").attr("connectes_sms") == "0") {
                $("#connecter .desc").last().text("Cet utilisateur n'accepte pas d'être contacté par sms");
            } else {
                $("#connecter .desc").last().text("Envoyer un SMS au 723 en commençant par CAP DIAL "+ $("#connecter").attr("connectes_id") +" suivi de votre message.").append("<p>Valable uniquement avec un numéro suisse. Service gratuit.</p>");
            }
            
            if(eventData.data.user_connected_alert_email == "0"){
                $("#connecter .desc").first().text("Pour pouvoir contacter le covoitureur, veuillez d'abord accepter de ").append("<a href='#/profil'>recevoir les e-mails</a>")	
                $("#connecter form").fadeOut();

            } else if($("#connecter").attr("connectes_alert_email") == "0") {
                $("#connecter .desc").first().text("Cet utilisateur n'accepte pas d'être contacté par email");
                $("#connecter form").fadeOut();

            } else {
                $("#connecter form").fadeIn();
            }
            
    	}
    });


});


$(document).on("click", "#connecter .btn ",function(e){
    e.preventDefault();

    $.ajax({
		url: API_URL + 'contact-cov.php',
		type: 'post',
		data: {
			message: $("#connecter #message").val(),
            id_user: $(".detail-header").attr("data-id"),
            locality_start: $(".detail-header").attr("data-locality_start"),
            locality_stop: $(".detail-header").attr("data-locality_stop"),
            id_trajet: $(".detail-header").attr("data-id_course"),
            
		}
	})
	.done(function(resp) {
        $(".error").remove();
		if (resp) {
			if (resp.status) {
				$(".notif").attr("id", "contact-covoitureur-email");
                notification.show(resp.errors);
                $("#connecter #message").val("");
                $("#connecter #message").parent().removeClass("has-focus");
			}
			else {
                $("<p>",{ class : "error" }).text(resp.errors).insertAfter("#connecter .input-container");
			}
		}
    });
    return false;
});