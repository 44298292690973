$(document).on("click", ".login-out .submit", function (e) {
	e.preventDefault();
	$.ajax({
		url: API_URL + 'logout.php'
	})
	.done(function(resp) {
		$(".check-cennect").fadeOut();
		$("#profil a").text("Mon profil")
		Router.navigate("accueil");
	})
})

$(document).on("click", ".login-out .close", function (e) {
	e.preventDefault();
	if(window.history){
		window.history.go(-1);
	}
})
