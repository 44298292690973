var filterOptions ;

var can_trigger_dropdown_change_event = true;

$(document).on("click", ".filters #startstop .switcher-item", function () {
	if ($(this).data("value") == "start") {
		$(".filters .placetype-slider").removeClass("stop");

		if (filterOptions.startpre === true) {
			$(".filters .placetype-slider .dropdown").val(filterOptions.startaddress);
			$(".filters .placetype-slider .input").val("");
		}
		else if (filterOptions.startpre === false) {
			$(".filters .placetype-slider .dropdown").val("");
			$(".filters .placetype-slider .input").val(filterOptions.startaddress);
		}
		else {
			$(".filters .placetype-slider .input, .filters .placetype-slider .dropdown").val("");
		}
		$("#placetype .switcher-item").eq(0).text("Lieu de départ");
	}
	else {
		$(".filters .placetype-slider").addClass("stop");

		if (filterOptions.stoppre === true) {
			$(".filters .placetype-slider .dropdown").val(filterOptions.stopaddress);
			$(".filters .placetype-slider .input").val("");
		}
		else if (filterOptions.stoppre === false) {
			$(".filters .placetype-slider .dropdown").val("");
			$(".filters .placetype-slider .input").val(filterOptions.stopaddress);
		}
		else {
			$(".filters .placetype-slider .input, .filters .placetype-slider .dropdown").val("");
		}
		$("#placetype .switcher-item").eq(0).text("Lieu d'arrivée");
	}

	$(".filters .placetype-slider .input").focusin().focusout();
	can_trigger_dropdown_change_event = false;
	$(".filters .placetype-slider .dropdown").change();
	can_trigger_dropdown_change_event = true;
})

$(document).on("click", ".filters #placetype .switcher-item", function () {
	if ($(this).data("value") == "input") {
		$(".filters .placetype-slider").removeClass("predifined");
	}
	else {
		$(".filters .placetype-slider").addClass("predifined");
	}
})

$(document).on("change", ".filters .placetype-slider .input", function () {
	var $this = $(this);
	var address = $this.val();
	var lat = $this.attr("data-lat");
	var lng = $this.attr("data-lng");

	if ($(".filters .placetype-slider").is(".stop")) {
		filterOptions.stoppre = false;
		filterOptions.stopaddress = address;
		filterOptions.stoplat = lat;
		filterOptions.stoplng = lng;
	}
	else {
		filterOptions.startpre = false;
		filterOptions.startaddress = address;
		filterOptions.startlat = lat;
		filterOptions.startlng = lng;
	}

	can_trigger_dropdown_change_event = false;
	$(".filters .placetype-slider .dropdown").val("").change();
	can_trigger_dropdown_change_event = true;
})

$(document).on("change", ".filters .placetype-slider .dropdown", function () {
	if (can_trigger_dropdown_change_event) {
		var $this = $(this);
		var address = $this.val();
		var lat = $this.find("option[value='" + address + "']").attr("data-lat");
		var lng = $this.find("option[value='" + address + "']").attr("data-lng");

		if ($(".filters .placetype-slider").is(".stop")) {
			filterOptions.stoppre = true;
			filterOptions.stopaddress = address;
			filterOptions.stoplat = lat;
			filterOptions.stoplng = lng;
		}
		else {
			filterOptions.startpre = true;
			filterOptions.startaddress = address;
			filterOptions.startlat = lat;
			filterOptions.startlng = lng;
		}

		$(".filters .placetype-slider .input").val("").focusin().focusout();
	}
})

$(document).on("click", ".filters .submit", function() {
	var driver = $(".filters #drivertype .switcher-item.active").data("value");
	filterOptions.driver = driver;

	Modal.dismiss(filterOptions.startlat || filterOptions.stoplat || filterOptions.driver ? filterOptions : null);
})