function AddCoursePageHandler(params, isload) {
	if (isload) {
		$("#footer .tab-new-course").addClass("active").siblings().removeClass("active");
	}
	Loader.show();

	$.ajax({
		url: API_URL + 'check-if-connected.php',
		method: 'post'
	})
	.done(function(resp) {
		if (resp && resp.status) {
			if(resp.prenom != "" && resp.name != ""){
				$("#header #profil a").text("Mon profil - ("+resp.prenom.substring(1,0).toUpperCase()+". "+resp.name+")");
			}
			$(".check-cennect").fadeIn();
			$.get("pages/add-course/add-course.html", function(html) {
				Router.handleNavigationChange(html, isload);
				$("body").removeClass("hidden");
				$(".dropdown").trigger("change");
				googleAutocomplete();
				$("#footer .tab-new-course").addClass("active").siblings().removeClass("active");
				if(resp.vehiculeData){
					if(resp.vehiculeData.vehicule!=""){
						$("#have-car").attr("checked",true);
						$("#marque").val(resp.vehiculeData.vehicule);
						$("#marque").parents(".input-container").addClass(" has-focus");
					} else {
						$("#have-car").parents(".section").css({
							maxHeight: 100 + 'px',
							position: 'relative',
							overflow: 'hidden'
						});
					}

					if(resp.vehiculeData.modele != ""){
						$("#model").val(resp.vehiculeData.modele);
						$("#model").parents(".input-container").addClass(" has-focus");
					}
					
					$("#color").val(resp.vehiculeData.couleur);
					$("#color").parents(".input-container").addClass(" has-focus");
					$("#co2").val(resp.vehiculeData.emission_co2);
					$("#co2").parents(".input-container").addClass(" has-focus");
					$("#nbr_places").val(resp.vehiculeData.nbr_places);
					$("#nbr_places").parents(".input-container").addClass(" has-focus");
					$("#plaque").val(resp.vehiculeData.num_plaque);
					$("#plaque").parents(".input-container").addClass("has-focus");
					if(resp.vehiculeData.autre_plaques != "" && resp.vehiculeData.autre_plaques){
						var autre_plaques= resp.vehiculeData.autre_plaques.split(';');
						var input = $(".autre_plaques .input-container").clone();
						autre_plaques.forEach(function(element) {
						  if(element != ""){
								$(input).clone().insertBefore($(".autre_plaques .link"));
								$(".autre_plaques .input-container").last().find("input").val(element);
								$(".autre_plaques .input-container").last().addClass("has-focus");
							}
						})
					}
					$(".dropdown[name=travel_with]").val(resp.vehiculeData.voyage_avec);
					$(".dropdown[name=travel_with]").parents(".input-container").addClass("has-focus");
					$("#accept_animals").attr("checked",(resp.vehiculeData.accepte_animaux== "1")? true :false );
					$("#accept_smoke").attr("checked",(resp.vehiculeData.accepte_fumee== "1")? true :false );
					$("#share_costs").attr("checked",(resp.vehiculeData.partage_frais== "1")? true :false );
				}
				Loader.hide();
			})
		}
		else {
			Router.navigate("se-connecter-dabbord");
		}
	});
}