function LoginPageHandler(params, isload) {
	$.get("pages/login/login.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");
		
		$.ajax({
			url: API_URL + 'logout.php'
		})

		if(window.localStorage){
			if(window.localStorage.getItem("password-accepte")){
				notification.show("Nouveau mot de passe accepté, vous pouvez maintenant vous identifier");
				localStorage.removeItem("password-accepte");
			}
        }
	})
}