$(document).on("click", ".profile .submit", function (e) {
	e.preventDefault();

	if($(this).hasClass("loading")){
		return false;
	}

	$(".profile .submit").addClass("loading");

	var autre_platque = "";
	$.each($(".autre_plaques .input-container"), function (index, element) { 
		if($(element).find("input").val() != ""){
			autre_platque += $(element).find("input").val()+";";
		}
	});

	$.ajax({
		url: API_URL + 'save-profil.php',
		type: 'post',
		data: {
			userimg : user_uploaded_image ,
			genre : $('input[name=genre]:checked').val(),
			username : $("#profil #username").val(),
			email: $("#profil #email").val(),
			nom: $("#profil #firstname").val(),
			prenom: $("#profil #lastname").val(),
			mobile: $("#profil #tel").val().replace(/\s/g,''),
			age: $("#profil #age").val(),
			vehicule : $("#marque").val(),
			modele : $("#model").val(),
			couleur: $("#color").val(),
			nbr_places : $("#nbr_places").val(),
			num_plaque : $("#plaque").val(),
			emission_co2 : $("#co2").val(),
			accepte_fumee : Number($("#profil #accept_smoke").is(":checked")),
			accepte_animaux : Number($("#profil #accept_animals").is(":checked")),
			partage_frais : Number($("#profil #share_costs").is(":checked")),
			voyage_avec : Number(($("#profil #travel_with").val()=="")?-1:$("#profil #travel_with").val()),
			sms : Number(($("#profil #sms").is(":checked")) ? 1 : 0),
			alert_email : Number(($("#profil #alert_email").is(":checked")) ? 1 : 0),
			autre_plaques: autre_platque
		}
	})
	.done(function(resp) {
		$(".profile .error").remove();
		$(".profile form > .msg").text("");
		
		if (resp) {
			$(".profile .submit").removeClass("loading");
			if (resp.status) {
				macaron_uploaded_image = "";
				notification.show("Votre profil a bien été modifié");
			}
			else {
				if (resp.errors && Object.keys(resp.errors).length) {
					for(var key in resp.errors) {
						var $target = $("#profil [name='" + key + "']").parents(".input-container");
						if ($target.length) {
							var margin = parseInt($target.css("margin-bottom")) - 5;
							$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -margin).insertAfter($target);
						}

						if( key == "alert_email" ){
							var $target = $("#profil [name='" + key + "']").parents(".checkboxes");
							$("<p>", { class: "error" }).text(resp.errors[key]).css("margin-top", -15).insertAfter($target);
						}
					}

					if (resp.errors.from) {
						$("#profil .form-error").append(resp.errors.from);
					}

					var $firsterror = $("#profil .error").first();
					if($firsterror.length) {
						$("html, body").animate({
							scrollTop: $firsterror.offset().top - 150
						}, 1000);
					}
				}
			}
		}
	})
})

$(document).on("input", "#profil .input", function() {
	$(this).parent().next(".error").remove();
	$("#profil form > .msg").text("");
});

$(document).on("change", "#profil #image", function(){

	if($("#profil .image-user").hasClass("loading")){
		return false;
	}

	if (this.files && this.files[0]) {

		$("#profil .image-user").addClass("loading");

		/*var reader = new FileReader();
		
		reader.onload = function(e) {

		  $("#profil .image-user").css({
			  backgroundImage: "url('" + e.target.result + "')",
			  backgroundSize: "cover"
		  });

		  user_uploaded_image = e.target.result;

		  user_uploaded_image = user_uploaded_image.split(',')[1];

		  $("#profil .image-user").removeClass("loading");
		}

		reader.readAsDataURL(this.files[0]);*/

		var file = this.files[0];

		loadImage.parseMetaData(file, function(data) {
			var orientation = 0;
			if (data.exif) {
			    orientation = data.exif.get('Orientation');
			}
			var loadingImage = loadImage(
			   	file,
			    function(canvas) {
			        var base64data = canvas.toDataURL('image/jpeg');

			        $("#profil .image-user").css({
						backgroundImage: "url('" + base64data + "')",
						backgroundSize: "cover"
					});

			        var img_src = base64data.replace(/^data\:image\/\w+\;base64\,/, '');
			        user_uploaded_image = base64data;

			        $("#profil .image-user").removeClass("loading");
			    }, {
			        canvas: true,
			        orientation: orientation
			    }
			);
		});

	} else {
		$("#profil .image-user").removeClass("loading");
	}
	
});


