function ResultsPageHandler(params, isload) {
	if (isload) {
		$("#footer .tab-map").addClass("active").siblings().removeClass("active");
	}

	Loader.show();
	$.get("pages/results/results.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");
		getSearchResults(params, Loader.hide);
	})
}

$(document).on("modal-dismissed", function(e, eventData) {
	if (eventData && eventData.source == "filters") {
		Loader.show();
		getSearchResults({
			lat1: eventData.data.startlat,
			lng1: eventData.data.startlng,
			lat2: eventData.data.stoplat,
			lng2: eventData.data.stoplng,
			driver: eventData.data.driver
		}, function() {
			Loader.hide();
		});
	}
})

function getSearchResults(params, callback) {

	$(".results .shadow, .results .close").remove();
	var map = generateMaps();

	if (this.markerClusterer) {
		markerClusterer.clearMarkers();
	}

	var query = [];
	if(params) {
		if (params.lat1)
			query.push("startlat=" + params.lat1);
		if (params.lng1)
			query.push("startlng=" + params.lng1);
		if (params.lat2)
			query.push("stoplat=" + params.lat2);
		if (params.lng2)
			query.push("stoplng=" + params.lng2);

		if (params.driver)
			query.push("driver=" + params.driver);
	}

	$.ajax({
		url: API_URL + 'trips.php?' + query.join("&"),
		type: 'get',
		dataType: 'json'
	})
	.done(function(resp) {
		if (resp.length) {
			$(".results .items-container").prepend($("<span>", { class: "close" }));
			$(".results .items-container").prepend($("<div>", { class: "shadow" }));
		}

		var markers = [];
		//var bounds = new google.maps.LatLngBounds();

		$(".results .items").empty();
		$(".results .items-container .counter").text(resp.length + " trajets trouvés");

		var iconOptions = {
			scaledSize: new google.maps.Size(35, 48),
	        labelOrigin: new google.maps.Point(17.5, 19)
		}

		$.each(resp, function(i, item) {
			if (item.lat_start && item.long_start) {

				var markerPos = { lat: parseFloat(item.lat_start), lng: parseFloat(item.long_start) };

				var marker = new google.maps.Marker({
					id: i + 1,
	            	position: markerPos,
	            	icon: $.extend({}, iconOptions, { url: 'img/icons/icon-pin-map-off.svg'}),
	            	label: {
						text: "1",
						color: "#C9D470",
						fontSize: "15px",
						fontWeight: "bold"
					}
	          	});

	          	marker.addListener('click', function() {

					centermap.latlng = map.getCenter().toJSON();
					centermap.zoom =  map.getZoom();

					for(var i = 0, mark; i < markers.length, mark = markers[i]; i++) {
						mark.setIcon($.extend({}, iconOptions, { url: "img/icons/icon-pin-map-off.svg" }));
					}

					marker.setIcon($.extend({}, iconOptions, { url: "img/icons/icon-pin-map-on.svg" }));

					map.panTo(new google.maps.LatLng(markerPos.lat + .05, markerPos.lng));

					var $target = $(".results .items-container .item").filter("[data-marker-id=" + marker.id + "]");
					var $item = $target.clone();
					$item.find(".avatar").remove();

					$item.click(function() {
						$target.trigger("click");
					})

					if (window.infowindow) {
						window.infowindow.close();
					}

					window.infowindow = new InfoBox({
						content: $item.get(0),
						pixelOffset: new google.maps.Size(-125, -65),
						closeBoxURL: location.origin + location.pathname + "img/icons/icon-close.svg"
					});

					window.infowindow.open(map, marker);
				});

				markers.push(marker);
	          	//bounds.extend(marker.getPosition());
			}

			var $item = $("<div>",  { class: "item", 'data-marker-id': i + 1, 'data-id': item.id_course });


			$item.append($("<div>", { class: "top" }));

			$item.find(".top").append($("<div>", { class: "image" }));

			$item.find(".top .image").append($("<img>", { class: "avatar", src: item.userimg ? ( img_URL + item.userimg) : ((item.genre_h == "0")? "img/icons/woman.svg" : "img/icons/man.svg" ) }));
			
			$item.find(".top .image").css({
				backgroundImage : 'url("'+$item.find(".top .image .avatar").attr("src")+'")' 
			})

			$item.find(".top").append($("<div>", { class: "infos" }));

			$item.find(".top .infos").append($("<p>", { class: "name" }).html((item.username.indexOf('@')!=-1) ? item.prenom.toLowerCase() : item.username.toLowerCase()));
			
			/*if (item.mo_status == 1 || item.mo_status == 2) {
				$item.find(".top .infos").append($("<p>", { class: "date" }).html((item.mo_status == 1) ? "Conducteur" : "Passager"));
			}*/
			
			if (item.user_type && item.user_type != "") {
				$item.find(".top .infos").append($("<p>", { class: "date" }).html(item.user_type));
			}
			
			$item.find(".top .infos").append($("<p>", { class: "date" }).html( ( item.occ_date == "00.00.0000" ) ? "Trajet Régulier" : "Trajet: " + item.occ_date ));
			
		
			$item.find(".top .infos").append($("<p>", { class: "co2" }).html( (item.co2 != "0" ) ? "Co2 "+ item.co2 +" Kg" : "" ));

			$item.append($("<div>", { class: "bottom " + (~~item.course_return ? "is-return" : "") }));
			$item.find(".bottom").append($("<div>", { class: "from" }));
			
			var time_from = item.mo_g_time || item.tu_g_time || item.we_g_time || item.th_g_time || item.fr_g_time || item.sa_g_time || item.su_g_time || item.occ_g_time;

			$item.find(".bottom .from").append($("<span>", { class: "time" }).html(
					(( time_from.split(':')[0].length == 1 ) ? "0" + time_from.split(":")[0] : time_from.split(":")[0]) + ":" + ((time_from.split(':')[1].length == 1 ) ? "0"+ time_from.split(':')[1] : time_from.split(':')[1])
			));

			$item.find(".bottom .from").append($("<span>", { class: "address" }).html(handleAddressCity(item.locality_start)));
			
			$item.find(".bottom").append($("<div>", { class: "to" }));

			var time_to = item.mo_r_time || item.tu_r_time || item.we_r_time || item.th_r_time || item.fr_r_time || item.sa_r_time || item.su_r_time || item.occ_r_time;
			
			$item.find(".bottom .to").append($("<span>", { class: "time" }).html((( time_to.split(':')[0].length == 1 ) ? "0" + time_to.split(":")[0] : time_to.split(":")[0]) + ":" + ((time_to.split(':')[1].length == 1 ) ? "0"+ time_to.split(':')[1] : time_to.split(':')[1])));

			/*
			if(item.course_return == "0" ){
				$item.find(".bottom .to").append($("<span>", { class: "time" }).css({
					paddingRight : "42px"
				}));
			} else {
				$item.find(".bottom .to").append($("<span>", { class: "time" }).html((( time_to.split(':')[0].length == 1 ) ? "0" + time_to.split(":")[0] : time_to.split(":")[0]) + ":" + ((time_to.split(':')[1].length == 1 ) ? "0"+ time_to.split(':')[1] : time_to.split(':')[1])));
			}
			*/
			

			$item.find(".bottom .to").append($("<span>", { class: "address" }).html(handleAddressCity(item.locality_stop)));

			$(".results .items").append($item);

			$item.find(".avatar").on("error",function(){
				$(this).attr("src", (item.genre_h == "0") ? "img/icons/woman.svg" : "img/icons/man.svg" );

				$item.find(".top .image").css({
					backgroundImage : 'url("'+$item.find(".top .image .avatar").attr("src")+'")' 
				})
			});
		});

		markerClusterer = new MarkerClusterer(map, markers, {
			styles: [{
				textSize: 15,
				textColor: '#C9D470',
				url: 'img/icons/icon-cluster.svg',
				width: 35,
				height: 48,
				anchor: [-10, 0]
			}],
			zoomOnClick: false
		});

		google.maps.event.addListener(markerClusterer, 'clusterclick', function(cluster) {
			/*var maxZoom = map.mapTypes[map.getMapTypeId()].maxZoom;
			if (map.getZoom() == maxZoom) {
			}*/

			var markers = cluster.getMarkers();
			/*var all_are_the_same = !!markers.reduce(function(a, b) {
				var aPos = a ? a.getPosition() : null;
				var bPos = a ? b.getPosition() : null;

				return a && (aPos.lat() == bPos.lat() && aPos.lng() == bPos.lng()) ? a : false;
			})*/

			var all_are_the_same = markers.every(function(marker) {
				var pos = marker.getPosition();
				var basePos = markers[0].getPosition();
				return pos.lat() == basePos.lat() && pos.lng() == basePos.lng();
			})

			if (all_are_the_same) {
				var ids = markers.map(function(marker) { return marker.id });
				var $items = $(".results .items .item").filter(function(i, item) {
					return ids.indexOf(parseInt($(item).attr("data-marker-id"))) != -1;
				}).clone();


				Modal.present("cluster-items", function() {
					$(".cluster-items .items").html($items);
				});
			}
			else {
				map.fitBounds(cluster.getBounds());
			}
		});

		//map.fitBounds(bounds);
		//var center = map.getCenter();
		//map.panTo(new google.maps.LatLng(center.lat() - 1, center.lng()));


		google.maps.event.addListener(map, 'zoom_changed', function() {
			centermap.latlng = map.getCenter().toJSON();
			centermap.zoom =  map.getZoom();
		});

		google.maps.event.addListener(map, 'dragend', function() { 
			centermap.latlng = map.getCenter().toJSON();
			centermap.zoom =  map.getZoom();
		});
		
		Loader.hide();

		

		if(window.localStorage){
			if(localStorage.getItem("back") && centermap.latlng!=""){
				var latlng = new google.maps.LatLng(centermap.latlng.lat , centermap.latlng.lng);
				map.setCenter(centermap.latlng);
				map.setZoom(centermap.zoom);
				centermap = { latlng : "" , zoom : 9 };
				localStorage.removeItem("back");
			}
		}
		

		if (callback)
			callback();
	});
}

var centermap = { latlng : "" , zoom : 9 };

function handleAddressCity(address) {
	var array = address.split(',');
	if (array.length > 1) {
		var city = array[array.length - 1];
		array[array.length - 1] = "<span class='light'>" + city + "</span>";
	}
	return array.join(',');
}