function MapPageHandler(params, isload) {
	if (isload) {
		$("#footer .tab-map").addClass("active").siblings().removeClass("active");
	}

	$.get("pages/results/results.html", function(html) {
		Router.handleNavigationChange($(html).addClass("map-only"), isload);
		$("body").removeClass("hidden");		
		getMapResults();
	})
}

function getMapResults(callback) {
	var map = generateMaps();

	$.ajax({
		url: API_URL + 'trips.php',
		type: 'get',
		dataType: 'json'
	})
	.done(function(data) {
		var markers = [];

		$.each(data, function(i, item) {
			if (item.lat_start && item.long_start) {
				var marker = new google.maps.Marker({
					id: i + 1,
	            	position: { lat: parseFloat(item.lat_start), lng: parseFloat(item.long_start) },
	            	icon: {
	            		url: 'img/icons/icon-pin-map-off.svg',
	            		scaledSize: new google.maps.Size(35, 48),
	            		labelOrigin: new google.maps.Point(17.5, 19)
	            	},
	            	label: {
						text: "1",
						color: "#C9D470",
						fontSize: "15px",
						fontWeight: "bold"
					}
	          	})

				markers.push(marker);
			}
		});

		new MarkerClusterer(map, markers, { styles: [{
			textSize: 15,
			textColor: '#C9D470',
			url: 'img/icons/icon-cluster.svg',
			width: 35,
			height: 48,
			anchor: [-10, 0]
		}]});

		if (callback)
			callback();
	});
}