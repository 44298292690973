function DetailsPageHandler(params, isload) {
	if(params) {
		Loader.show();
		$.get("pages/details/details.html", function(html) {
			Router.handleNavigationChange(html, isload);
			$("body").removeClass("hidden");

			$('html,body').animate({ scrollTop: 0}, 0);

			$.ajax({
				url: API_URL + 'detail-course.php?courseId=' + params.id,
				type: 'get',
				dataType: 'json'
			})
			.done(function(data) {

				$(".detail-header").attr("data-id",data.id_user);
				$(".detail-header").attr("data-id_course",data.id_course);
				$(".detail-header").attr("data-locality_start",data.locality_start);
				$(".detail-header").attr("data-locality_stop",data.locality_stop);

				$("#details-trajet .image").append('<img alt="" />');
				
				if(data.userimg != "" ){
					$("#details-trajet .image img").attr("src",img_URL+data.userimg);
					
				} else {
					$("#details-trajet .image img").attr("src",(data.genre_h == 1)? "img/icons/man.svg" : "img/icons/woman.svg" );
				}
				
				$("#details-trajet .image img").on("error",function(){
					$(this).attr("src", (data.genre_h == "0") ? "img/icons/woman.svg" : "img/icons/man.svg" );
					$("#details-trajet .image").css({
						backgroundImage: "url('"+$(".image img").attr("src")+"')"
					})
				});

				$("#details-trajet .image").css({
					backgroundImage: "url('"+$(".image img").attr("src")+"')"
				})
				
				$(".detail-header .title").html((data.username.indexOf('@')!=-1) ? data.prenom.toLowerCase() : data.username.toLowerCase());

				// locality start;

				if(data.locality_start) {
					var locality_start= data.locality_start.split(",");
					var address = "";
					for(var i=0; i < locality_start.length-1; i++){
						if(i>0){address += ",";	}
						address += locality_start[i];
					}

					$(".detail-body .item .blog").eq(0).find(".right p").html(address);
					$(".detail-body .item .blog").eq(0).find(".right span").html(locality_start[locality_start.length-1]);

				}

				if(data.locality_stop){
					var locality_stop= data.locality_stop.split(",");
					var address = "";
					for(var i=0; i < locality_stop.length-1; i++){
						if(i>0){address += ",";	}
						address += locality_stop[i];
					}

					$(".detail-body .item .blog").eq(1).find(".right p").html(address);
					$(".detail-body .item .blog").eq(1).find(".right span").html(locality_stop[locality_stop.length-1]);
				}

				var i = 0;
				if(data.locality_step == ""){
					$(".detail-body .item").eq(1).children(".blog").eq(i).remove();

				} else {
					$(".detail-body .item").eq(1).children(".blog").eq(i).find(".right p").html(data.locality_step.split(',')[0]);
					$(".detail-body .item").eq(1).children(".blog").eq(i).find(".right span").html(data.locality_step.split(',')[1]);

					i+=1;
				}

				if( data.distance_step1 == "" || data.distance_step2 == "" ){
					$(".detail-body .item").eq(1).children(".blog").eq(i).remove();

				} else {
					var distance = parseFloat(data.distance_step1) + parseFloat(data.distance_step2);

					if( distance == 0){
						$(".detail-body .item").eq(1).children(".blog").eq(i).remove();
					}else {

						$(".detail-body .item").eq(1).children(".blog").eq(i).find(".right p").html(Math.round(distance)+" Km");
						i+=1;
					}
					
					var essence = 8.5 * distance/100;

					if(essence == 0){
						$(".detail-body .item").eq(1).children(".blog").eq(i).remove();
					}else {
						$(".detail-body .item").eq(1).children(".blog").eq(i).find(".right p").html(Math.round(essence)+" l");
						i+=1;
					}

					//var co2  = parseFloat(data.co2);

					if(data.co2 == 0){
						$(".detail-body .item").eq(1).children(".blog").eq(i).remove();
					} else {

						/*if (parseFloat(data.emission_co2) < 80 || parseFloat(data.emission_co2) > 300) {
							co2 = 170;
						}

						co2 = co2 * distance * 1 /1000  ;

						*/

						$(".detail-body .item").eq(1).children(".blog").eq(i).find(".right p").html(data.co2+" Kg");
						i+=1;
					}
				}

				if( data.type_course == "" ){
					$(".detail-body .item").eq(1).children(".blog").eq(i).remove();
				} else {
					$(".detail-body .item").eq(1).children(".blog").eq(i).find(".right p").html((data.type_course == "0" )? "Régulier" : "Occasionnel" );
				}
				$blog = $(".detail-body .item").eq(1).children(".blog").first().clone();
				

				

				if(data.type_course == "1" ){
					$(".detail-body .item").eq(2).html("");
					$blog_trajet = $blog.clone();
					$($blog_trajet).find(".left p").html("Date du trajet");
					$($blog_trajet).find(".right p").html(data.occ_date);
					$(".detail-body .item").eq(2).append($blog_trajet);

					$blog_time = $blog.clone();
					$($blog_time).find(".left p").html("Aller / Retour");
					$($blog_time).find(".right p").html(data.occ_g_time + " / " + data.occ_r_time);
					$(".detail-body .item").eq(2).append($blog_time);

					$blog_statut = $blog.clone();
					$($blog_statut).find(".left p").html("Statut");

					$($blog_statut).find(".right p").html((data.occ_status == "0") ? "C/P" : (data.occ_status == "1") ? "C" : "P") ;
					$(".detail-body .item").eq(2).append($blog_statut);
					

				} else {

					//go

					var day_go = [ "mo_g_time", "tu_g_time", "we_g_time", "th_g_time", "fr_g_time", "sa_g_time", "su_g_time" ];

					var day_return = [ "mo_r_time", "tu_r_time", "we_r_time", "th_r_time", "fr_r_time", "sa_r_time", "su_r_time" ];

					var satut = [ "mo_status", "tu_status", "we_status", "th_status", "fr_status", "sa_status", "su_status" ]

					$("#go span").each(function(index,el){
						$(el).html(( data[day_go[index]] == "") ? "-" : ((data[day_go[index]].split(':')[0].length == 1 ) ? "0"+data[day_go[index]].split(":")[0] : data[day_go[index]].split(":")[0]) + ":" + ((data[day_go[index]].split(':')[1].length == 1 ) ? "0"+data[day_go[index]].split(':')[1] : data[day_go[index]].split(':')[1]) );
					});

					// return

					$("#return span").each(function(index,el){

						$(el).html(( data[day_return[index]] == "") ? "-" : ((data[day_return[index]].split(':')[0].length == 1 ) ? "0"+data[day_return[index]].split(":")[0] : data[day_return[index]].split(":")[0]) + ":" + ((data[day_return[index]].split(':')[1].length == 1 ) ? "0"+data[day_return[index]].split(':')[1] : data[day_return[index]].split(':')[1]) );
					});

					if( data.course_return == "0"){

						$("#return").prev().text("arrivée");
					} else {
						$("#return").prev().text("retour");
						
					}

					// statut

					$("#satut span").each(function(index,el){

						if(data[satut[index]] == "" ){
							$(el).html("-");
						}

						if(data[satut[index]] == "0" ){
							$(el).html("C/P");
						}

						if(data[satut[index]] == "1" ){
							$(el).html("C");
						}

						if(data[satut[index]] == "2" ){
							$(el).html("P");
						}
					});

				}

				i=0;

				if (data.vehicle == "") {
					$(".detail-body .item").eq(4).children(".blog").eq(i).remove();
				}
				else {
					$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html(data.vehicle);
					i += 1;
				}

				
				if (data.other_color) {
					$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html(data.other_color);
				} else {
					if (data.couleur == "0") {
						$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html("Non définie");
					} else {
						var couleur = ["Indéfini", "Blanc", "Gris", "Noir", "Rouge", "Orange", "Jaune", "Bleu", "Vert", "Violet"];
						$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html(couleur[data.couleur]);
					}
				}
				
				i += 1;

				$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html((data.nbr_places == "0") ? "Indéfini" : data.nbr_places + " places");

				i += 1;

				/*
				var lang = { lang_fr :"Français", lang_de :"Allemand", lang_en :"Anglais", lang_es :"Espagnol", lang_it :"Italien" },
					l = "", 
					k = 0;

				$.each(lang,function(index,item){

					if(data[index] == "1"){

						if(k>0){
							l+=",";
						}
						
						l+= " " + item;
						k++;
					}
				});

				if (l == "") {
					$(".detail-body .item").eq(4).children(".blog").eq(i).remove();
				}
				else {
					$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html(l);
					i += 1;
				}

				*/

				//$(".detail-body .item").eq(4).children(".blog").eq(i).remove();

				var travel_with = { 0 :"Des femmes ou des hommes", 1 :"Des femmes seulement", 2 :"Des hommes seulement" };

				$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html(travel_with[data.travel_with]);
				i+=1;
				$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html( (data.accept_smoke=="0")? "N'accepte pas la fumée" : "Accepte la fumée" );
				i+=1;
				$(".detail-body .item").eq(4).children(".blog").eq(i).find("span").eq(1).html( (data.accepte_animaux=="0")? "N'accepte pas les animaux" : "Accepte les animaux" );

				var locality_start = new google.maps.LatLng( parseFloat(data.lat_start), parseFloat(data.long_start)),
					locality_stop = new google.maps.LatLng(parseFloat(data.lat_stop), parseFloat(data.long_stop));

				var map = generateMaps({lat: parseFloat(data.lat_start), lng: parseFloat(data.long_start)});

				directionsService = new google.maps.DirectionsService,
				directionsDisplay = new google.maps.DirectionsRenderer({
					map: map
				}),
				
				markerA = new google.maps.Marker({
					position: locality_start,
					clickable: false,
					map: map,
					icon: {
	            		url: 'img/icons/icon-pin-map-off.svg',
	            		scaledSize: new google.maps.Size(35, 48)
	            	}
				}),

				markerB = new google.maps.Marker({
					position: locality_stop,
					clickable: false,
					map: map,
					icon: {
	            		url: 'img/icons/icon-pin-map-off.svg',
	            		scaledSize: new google.maps.Size(35, 48)
	            	}
				});

				var poly = new google.maps.Polyline({
					path: [],
					strokeColor: "#033044",
					strokeOpacity: 0.8,
					strokeWeight: 3
				});
				
				// get route from A to B
				calculateAndDisplayRoute(directionsService, directionsDisplay, locality_start, locality_stop);
				
				poly.setMap(map);
				
				var bounds = new google.maps.LatLngBounds();

				bounds.extend(markerA.getPosition());
				bounds.extend(markerB.getPosition());

				map.fitBounds(bounds);

				$(window).on("resize", function() {
					map.fitBounds(bounds);
				})

				function calculateAndDisplayRoute(directionsService, directionsDisplay, locality_start, locality_stop) {
					directionsService.route({
						origin: locality_start,
						destination: locality_stop,
						avoidTolls: true,
						avoidHighways: false,
						travelMode: google.maps.TravelMode.DRIVING
					}, function (response, status) {
						if (status == google.maps.DirectionsStatus.OK) {
							//directionsDisplay.setDirections(response);
							var legs = response.routes[0].legs;
							for (i = 0; i < legs.length; i++) {
								var steps = legs[i].steps;
								for (j = 0; j < steps.length; j++) {
									var nextSegment = steps[j].path;
									for (k = 0; k < nextSegment.length; k++) {
										poly.getPath().push(nextSegment[k]);
										//bounds.extend(nextSegment[k]);
									}
								}
							}
						} else {
							//window.alert('Directions request failed due to ' + status);
						}
					});
				}

				$.ajax({
					url: API_URL + 'check-if-connected.php',
					method: 'post'
				})
				.done(function(resp) {
					if (resp && resp.status) {
						$("#noconnecter").fadeOut(0);
						$("#connecter").fadeIn(0);
						$("#connecter").attr("connectes_sms",data.sms);
						$("#connecter").attr("connectes_alert_email",data.alert_email);

						if( data.user_connected_sms == "0"){
							$("#connecter .desc").last().text("Pour pouvoir contacter le covoitureur, veuillez d'abord accepter de ").append("<a href='#/profil'>recevoir les sms</a>");
						} else if(data.sms == "0") {
							$("#connecter .desc").last().text("Cet utilisateur n'accepte pas d'être contacté par sms");
						} else {
							$("#connecter .desc").last().text("Envoyer un SMS au 723 en commençant par CAP DIAL "+ data.id_user +" suivi de votre message.").append("<p>Valable uniquement avec un numéro suisse. Service gratuit.</p>");;
						}

						if(data.user_connected_alert_email == "0"){
							$("#connecter .desc").first().text("Pour pouvoir contacter le covoitureur, veuillez d'abord accepter de ").append("<a href='#/profil'>recevoir les e-mails</a>")							
							$("#connecter form").fadeOut();

						} else if(data.alert_email == "0") {
							$("#connecter .desc").first().text("Cet utilisateur n'accepte pas d'être contacté par email");
							$("#connecter form").fadeOut();

						} else {
							$("#connecter form").fadeIn();
						}
					}
					else {
						$("#connecter").fadeOut(0);
						$("#noconnecter").fadeIn(0);
					}
					$("#connecter").attr("connectes_id",data.id_user);
					Loader.hide();
				});

				
			});

			
		})
	}
}