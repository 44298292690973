function ParkingPageHandler(params, isload) {
	if (isload) {
		$("#footer .tab-parking").addClass("active").siblings().removeClass("active");
	}

	Loader.show();
	$.get("pages/parking/parking.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");

		getParkings(Loader.hide);
	})
}

function getParkings(callback) {
	var map = generateMaps();

	google.maps.event.addListener(map, 'zoom_changed', function() {
		$(".parking .shadow, .parking .titles").addClass("is-hidden");
	});

	$.ajax({
		url: API_URL + 'parkings.php',
		type: 'get',
		dataType: 'json'
	})
	.done(function(data) {
		var markers = [];
		//var bounds = new google.maps.LatLngBounds();

		var iconOptions = {
			scaledSize: new google.maps.Size(35, 48),
	        labelOrigin: new google.maps.Point(17.5, 19)
		}

		var infowindow = null;

		$.each(data, function(i, item) {
			if (item.coord_lat && item.coord_long) {

				var isAP = item.id_enterprise == "94";

				var markerPos = { lat: parseFloat(item.coord_lat), lng: parseFloat(item.coord_long) };

				var marker = new google.maps.Marker({
					id: i + 1,
	            	position: markerPos,
	            	is_ap: isAP,
	            	icon: $.extend({}, iconOptions, { url: isAP ? 'img/icons/icon-pin-map-ap-off.svg' : 'img/icons/icon-pin-map-park-off.svg'}),
	            	label: {
						text: isAP ? " " : "1",
						color: "#044b6c",
						fontSize: "15px",
						fontWeight: "bold"
					}
	          	});

				marker.addListener('click', function() {
					for(var i = 0, mark; i < markers.length, mark = markers[i]; i++) {
						if (mark.is_ap)
							mark.setIcon($.extend({}, iconOptions, { url: "img/icons/icon-pin-map-ap-off.svg" }));
						else {
							mark.setIcon($.extend({}, iconOptions, { url: "img/icons/icon-pin-map-park-off.svg" }));
							var label = mark.getLabel();
							label.color = "#044b6c";
							mark.setLabel(label);
						}
					}

					if (marker.is_ap)
						marker.setIcon($.extend({}, iconOptions, { url: "img/icons/icon-pin-map-ap-on.svg" }));
					else {
						marker.setIcon($.extend({}, iconOptions, { url: "img/icons/icon-pin-map-park-on.svg" }));
						var label = marker.getLabel();
						label.color = "#FFF";
						marker.setLabel(label);
					}

					map.panTo(new google.maps.LatLng(markerPos.lat + .25, markerPos.lng));

					var infowindowHtml = $("<div>", { class: "map-infowindow" });
					infowindowHtml.append($("<div>", { class: "infos" }));
					infowindowHtml.find(".infos").append($("<p>", { class: "title" }).text(item.title));
					
					/*if(item.nb_stickers != "0") {
						infowindowHtml.find(".infos").append($("<p>", { class: "places" }).text("Nombre de places: " + item.nb_stickers));
					}*/

					if(item.desc_short !=""){
						infowindowHtml.find(".infos").append($("<p>", { class: "places" }).html(item.desc_short.trim()));
					}
					if (isAP) { 
						infowindowHtml.append($("<hr>"));
						
						var $btn = $("<div>", { id: "demande-un-macaron", class: "button", "data-park-id": item.id_parking }).text("Demander un macaron");
						infowindowHtml.append($btn);

						$btn.click(function() {
							$(".parking").attr("data-selecte-park-title", $(this).parent().find(".title").html());
							$(".parking").attr("data-selecte-park-id", $(this).attr("data-park-id"));
							Loader.show();
							
							$.ajax({
								url: API_URL + "check-if-connected.php"
							})
							.done(function(resp) {
								Loader.hide();

								if (resp && resp.status) {
									//Modal.present("macaron", macaronModalHandler(resp));
									Modal.present("macaron",function(){
										macaronModalHandler();

										if(resp.showMacaron == 0){
											$("form").fadeOut();
											$(".alert").fadeIn();

										} else {
											getPredefinedLocation(function(locations) {
												var $select = $(".macaron .placetype-dropdown .dropdown");
												$.each(locations, function(i, location) {
													var $option = $("<option>");
													$option.attr("value", location.office_name);
													$option.text(location.office_name);
													$option.attr("data-lat", location.coord_lat);
													$option.attr("data-lng", location.coord_long);
													$select.append($option);
												})
											});
	
											if(resp.userimg != ""){
												var d =new Date();
												$("#macaron #image").parent().css({
													backgroundImage: "url('"+resp.userimg+"?"+d.getTime()+"')",
													backgroundSize: "cover"
												});
												parking_form_image_url = resp.userimg;
											}
											
											$("#name").val(resp.name).parent().addClass("has-focus");
											$("#lastname").val(resp.prenom).parent().addClass("has-focus");
	
	
										} 
										
									});
								}
								else {
									Modal.present("login");
								}
							})
						})
					}

					if (window.infowindow) {
						window.infowindow.close();
					}

					window.infowindow = new InfoBox({
						content: infowindowHtml.get(0),
						pixelOffset: new google.maps.Size(-125, -65),
						closeBoxURL: location.origin + location.pathname + "img/icons/icon-close.svg"
					});

					window.infowindow.open(map, marker);
				});

				markers.push(marker);

				//bounds.extend(marker.getPosition());
			}
		});

		new MarkerClusterer(map, markers, { styles: [{
			textSize: 15,
			textColor: '#044b6c',
			url: 'img/icons/icon-cluster-2.svg',
			width: 35,
			height: 48,
			anchor: [-10, 0]
		}]});

		//map.fitBounds(bounds);

		if (callback)
			callback();
	});
}

function macaronModalHandler() {
	var title = $(".parking").attr("data-selecte-park-title"),
		parkid = $(".parking").attr("data-selecte-park-id");
		
	$(".macaron .page-titles .title").attr("data-park-id", parkid).html(title);

	$(".macaron .datepicker").datepicker({
		beforeShow: function(input, obj) {
			$(obj.dpDiv).removeClass("right");

			if ($(input).is(".datepicker-right")) {
				$(obj.dpDiv).addClass("right");
			}

			$(obj.dpDiv).insertBefore($(input));

			if ($(input).parent().is(".input-container")) {
				$(input).parent().addClass("has-datepicker");
			}
		}
	});

	$(".macaron .dropdown").trigger("change");

}

$(document).on("modal-dismissed", function(e, eventData) {
	if (eventData && eventData.source == "login" && eventData.data) {
        if ($(".content.parking").length) {
			//Modal.present("macaron", macaronModalHandler(eventData.data));
			Modal.present("macaron",function(){
				macaronModalHandler();

				if(eventData.data.showMacaron == 0){
					$("form").fadeOut();
					$(".alert").fadeIn();
				} else {
					getPredefinedLocation(function(locations) {
						var $select = $(".macaron .placetype-dropdown .dropdown");
						$.each(locations, function(i, location) {
							var $option = $("<option>");
							$option.attr("value", location.office_name);
							$option.text(location.office_name);
							$option.attr("data-lat", location.coord_lat);
							$option.attr("data-lng", location.coord_long);
							$select.append($option);
						})
					});
	
					if(eventData.data.userimg != ""){
						var d =new Date();
						$("#macaron #image").parent().css({
							backgroundImage: "url('"+eventData.data.userimg+"?"+d.getTime()+"')",
							backgroundSize: "cover"
						});
						parking_form_image_url = eventData.data.userimg;
					}
					$("#macaron #name").val(eventData.data.name).parent().addClass("has-focus");
					$("#macaron #lastname").val(eventData.data.prenom).parent().addClass("has-focus");
	
				}
				
				
			});
			
			
        }
	}
});