$(document).on("click", ".remove-profile .submit", function (e) {
	e.preventDefault();

	$.ajax({
		url: API_URL + 'delete-profil.php',
		type: 'post',
		data: {
			user_id : $("#remove-profile").attr("data-id")
		}
	})
	.done(function(resp) {
		if (resp) {
			if (resp.status) {
				
				$(".check-cennect").fadeOut();
				$("#profil a").text("Mon profil")
				Router.navigate("accueil");
				$(".notif").attr("id", "supression-user");
				notification.show("Votre profil a bien été supprimé");
			}
		}
	})
})

$(document).on("input", "#profil .input", function() {
	$(this).siblings(".error").remove();
	$("#profil form > .error").text("");
});

