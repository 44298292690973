

$(document).on("click", ".macaron .submit", function (e) {

	e.preventDefault();

	$(".macaron form > .msg").text("");
	
	if($(this).hasClass("loading")){
		return false;
	}

	if( macaron_uploaded_image == "" && parking_form_image_url == "" ) {
		$(".macaron form > .msg").removeClass("success").addClass("form-error").append("La photo portrait est obligatoire");
		return false;
	}

	$(".macaron .submit").addClass("loading");

	$.ajax({
		url: API_URL + 'macaron.php',
		type: 'post',
		data: {
			parking_form_id: $(".macaron .page-titles .title").attr("data-park-id"),
			parking_form_titre: $(".macaron .page-titles .title").text(),
			parking_form_image: macaron_uploaded_image,
			parking_form_nom: $(".macaron #name").val(),
			parking_form_prenom: $(".macaron #lastname").val(),
			parking_form_lieu: $(".macaron #lieu_travail").val(),
			parking_form_com: $(".macaron #comment").val(),
			parking_form_image_url : $("#macaron #image").parent().css("backgroundImage").split('"')[1]
		}
	})
	.done(function(resp) {
		$(".macaron .submit").removeClass("loading");
		$(".macaron form > .msg").text("");
		if (resp) {
			if (resp.status) {
				macaron_uploaded_image = "";
				parking_form_image_url = "";
				$(".notif").attr("id", "demande-macaron");
				notification.show("Merci votre demande a bien été envoyée");
				$(".modal .modal-close-btn").trigger("click");
			}
			else {
				if (resp.errors) {
					$(".macaron form > .msg").removeClass("success").addClass("form-error").append(resp.errors);
				}
			}
		}
	})
})

$(document).on("input", ".macaron .input", function() {
	$(this).parent().next(".error").remove();
	$(".macaron form > .msg").text("");
});

var parking_form_image_url = "";
var macaron_uploaded_image = "";

$(document).on("change", ".macaron #image", function(){

	if($(".macaron .image-user").hasClass("loading")){
		return false;
	}

	if (this.files && this.files[0]) {
		$(".macaron .image-user").addClass("loading");
		/*var reader = new FileReader();
		
		reader.onload = function(e) {

		  $(".macaron .image-container").css({
			  backgroundImage: "url('" + e.target.result + "')",
			  backgroundSize: "cover"
		  });

		  macaron_uploaded_image = e.target.result;

		  macaron_uploaded_image = macaron_uploaded_image.split(',')[1];

		  $(".macaron .image-user").removeClass("loading");

		}

		reader.readAsDataURL(this.files[0]);*/

		var file = this.files[0];

		loadImage.parseMetaData(file, function(data) {
			var orientation = 0;
			if (data.exif) {
			    orientation = data.exif.get('Orientation');
			}
			var loadingImage = loadImage(
			   	file,
			    function(canvas) {
			        var base64data = canvas.toDataURL('image/jpeg');

			        $(".macaron .image-container").css({
						backgroundImage: "url('" + base64data + "')",
						backgroundSize: "cover"
					});

			        var img_src = base64data.replace(/^data\:image\/\w+\;base64\,/, '');
			        macaron_uploaded_image = base64data;

			        $(".macaron .image-user").removeClass("loading");
			    }, {
			        canvas: true,
			        orientation: orientation
			    }
			);
		});

	} else {
		$(".macaron .image-user").removeClass("loading");
	}
});

$(document).on("change", "#startdate, #enddate", function(){
	$(this).parent().addClass("has-focus");
});
