$(document).on("click", "#demande-cov .submit", function(e){
	e.preventDefault();
	$.ajax({
		url: API_URL + '/demande-cov.php',
		type: 'post',
		data : {
			step : 2,
			id_user : $("form").attr("data-userId"),
			id_trajet : $("form").attr("data-trajetId"),
			message : $("#message").val(),
			sujet : $("#sujet").text()
		}
	})
	.done(function(resp) {
		$(".msg").text("");
		if(resp) {
			if(resp.status){
				$(".demande-cov form").children(":not(.page-titles)").addClass("hide");
				$(".demande-cov .success").text(resp.errors).addClass("open");
			} else {
				$(".demande-cov form > .msg").removeClass("success").addClass("form-error").append(resp.errors);
			}
		}
	});
});