$(document).on("click", ".results .tabs .tab", function () {
	var index = $(this).index();
	var $items = $(".results .items-container");
	var $map = $(".results .maps-container");

	if (index == 1) {
		$(".results .tabs .active-border").removeClass("step3").addClass("step2");

		$items.addClass("is-hidden").removeClass("is-full");
		$map.removeClass("is-hidden").addClass("map-only");
	}
	else if (index == 2) {
		$(".results .tabs .active-border").removeClass("step2").addClass("step3");

		$items.removeClass("is-hidden").addClass("is-full");
		$map.addClass("is-hidden").removeClass("map-only");
	}
	else {
		$(".results .tabs .active-border").removeClass("step2 step3");

		$items.removeClass("is-hidden is-full");
		$map.removeClass("is-hidden map-only");
	}	
})

$(document).on("click",".results .item",function(e){
	if(window.localStorage){
		localStorage.setItem("back", "1");
	}
	Router.navigate("detail-trajet/" + $(this).attr("data-id"));
})

$(document).on("click", ".results .tabs-container .filter", function() {
	filterOptions = {
		startpre: null,
		startaddress: "",
		startlat: "",
		startlng: "",
		stoppre: null,
		stopaddress: "",
		stoplat: "",
		stoplng: ""
	};

	Modal.present("filters", function() {
		getPredefinedLocation(function(locations) {
			var $select = $(".filters .placetype-slider .dropdown");
			$.each(locations, function(i, location) {
				var $option = $("<option>");
				$option.attr("value", location.office_name);
				$option.text(location.office_name);
				$option.attr("data-lat", location.coord_lat);
				$option.attr("data-lng", location.coord_long);
				$select.append($option);
			})
		})

		$(".filters #startstop .switcher-item:first-child").trigger("click");

		if (filterOptions.driver) {
			$(".filters #drivertype .switcher-item[data-value='" + filterOptions.driver + "']").addClass("active").siblings().removeClass("active");
		}
		
		googleAutocomplete();
	})
})

$(document).on("click", ".items-container .close",function(){
	$(".results .tabs .active-border").removeClass("step3").addClass("step2");
	$(".results .items-container").addClass("is-hidden").removeClass("is-full");
	$(".results .maps-container").removeClass("is-hidden");
});