function SignupPageHandler(params, isload) {
	$.get("pages/signup/signup.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");
		$("#app-root").get(0).scrollHeight;
		$("#app-root .signup").addClass("pushed");

		$.get("pages/conditions/conditions.html",function(html){
			$("#conditions").append(html);
		});

		getCompanies(function (companies) {
			var $select = $("#inscription #id_company");
			$.each(companies, function (i, company) {
				var $option = $("<option>");
				$option.attr("value", company.id_company);
				$option.text(company.company_name);
				$select.append($option);
			})
		});
	})
}