
function MyTripsPageHandler(params, isload) {
	$.get("pages/my-trips/my-trips.html", function(html) {
		Router.checkAuth(function() {
			Router.handleNavigationChange(html, isload);
			$("body").removeClass("hidden");			
			getMyTrips();
			$( "html,body" ).scrollTop( 0 );
		})
	})
}

function getMyTrips(callback) {
	$.ajax({
		url: API_URL + 'my-trips.php',
		type: 'post',
		dataType: 'json'
	})
	.done(function(resp) {

		if(resp) {
			if(resp.length > 0){
				$.each(resp, function(i, item) {
					var $item = $("<div>",  { class: "item", 'data-marker-id': i + 1, "data-id": item.id_course });
					$item.append($("<span>", { class: "delete" }));
					$item.append($("<p>", { class: "title" }).text(item.destination));
					$item.append($("<p>", { class: "info" }).html('<span class="key">Date du trajet</span>:<span class="value">' + item.date_trip + '</span>'));
					$item.append($("<p>", { class: "info" }).html('<span class="key">Trajet créé le</span>:<span class="value">' + item.date_modify + '</span>'));
					$(".my-trips .items").append($item);
				});

				$("#footer .tab-new-course").removeClass("active");
				if(window.localStorage){
					if(window.localStorage.getItem("notif")) {
						$(".notif").attr("id", "proposer-nouveau-trajet");
						notification.show("Votre trajet a bien été ajouté");
						window.localStorage.removeItem("notif");
					}
				}
			} else {
				$(".aucun").fadeIn();
			}
		}

		if (callback)
			callback();
	});
}