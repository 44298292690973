function DemandeCovPageHandler(params, isload) {

	Loader.show();
	$.ajax({
		url: API_URL + 'check-if-connected.php',
		method: 'post'
	})
	.done(function(resp) {
		if (resp.status) {
			getDemandeCov(params, isload);
		}
		else {
			Loader.hide();
			Modal.present("login");
			$(document).on("modal-dismissed", function(e, eventData) {
				if (eventData && eventData.source == "login" && eventData.data) {
					getDemandeCov(params, isload);
				}
			});
		}
	});
}

function getDemandeCov(params, isload){
	Loader.show();
	$.get("pages/demande-cov/demande-cov.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");
		$.ajax({
			url: API_URL + '/demande-cov.php',
			type: 'post',
			data : {
				step : 1,
				id_trajet : params.trajet_id,
				id_user : params.user_id
			}
		})
		.done(function(resp) {
			if(resp){
				if(resp.status) {
					$("#sujet").text(resp.sujet);
					$("#sujet").parents("form").attr("data-userId",params.user_id);
					$("#sujet").parents("form").attr("data-trajetId",params.trajet_id);
				} else {
					$(".demande-cov form > .msg").removeClass("success").addClass("form-error").append(resp.errors);
					$(".submit").fadeOut(0);
				}
			}
			Loader.hide();
		});
	});
}