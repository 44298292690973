function loginOutPageHandler(params, isload) {
	$.ajax({
		url: API_URL + 'check-if-connected.php',
		method: 'post'
	})
	.done(function(resp) {
		if (resp && resp.status) {
			$.get("pages/login-out/login-out.html", function(html) {
				Router.handleNavigationChange(html, isload);
				$("body").removeClass("hidden");
			})
		}
	});
}