function HomePageHandler(params, isload) {
	if (isload) {
		$("#footer .tab-search").addClass("active").siblings().removeClass("active");
	}
	Loader.show();

	$.get("pages/home/home.html", function(html) {
		Router.handleNavigationChange(html, isload);
		$("body").removeClass("hidden");
		googleAutocomplete();
		Loader.hide();
	})
}